import { makeStyles, createStyles, withStyles, Badge } from "@material-ui/core";

export const useAppStyles = makeStyles(() =>
  createStyles({
    main: {
      padding: `5px`
    },
    img: {
      maxWidth: "40px",
      maxHeight: "40px"
    },
    imgRoot: {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "#951556",
      boxShadow: `0 0 0 0px red`
    },
    detail_name: {
      margin: 0
    },
    itemRoot: {
      paddingLeft: '3%',
      paddingRight: '65px',
    },

    list: {
      listStyle: "none",
      display: 'block',
      textDecoration: 'none',
      color: "#35013f",
      paddingBottom: 5,
      textAlign: 'center',
      '&:hover': {
        color: "#35013f"
      }
    },
    unread: {
      background: "#e9b5d2",
      color: "#951556",
      minWidth: "30px"
    },
    favorite: {
      color: "#951556"
    },
    newContact: {
      background: "#951556",
      color: "white",
    },
    link: {
      color: "#35013f",
      textDecoration: "none"
    },
    itemPrimary: {
      fontSize: "16px",
      fontWeight: 700
    },
    itemSecondary: {
      textOverflow: "ellipsis",
      fontSize: "15px",
      fontStyle: "italic",
      color: "#000000"
    },
    itemSecondaryCurrent: {
      flex: 1,
      overflow: "hidden",
      fontSize: "15px",
      fontStyle: "italic",
      color: "#FFF"
    },
    unreadOrNewConversation: {
      background: "#fff",
    },
    create: {
      color: '#951556'
    },
    currentConversation: {
      background: "#35013f",
      color: "#FFF",
      '&:hover': {
        color: "white"
      }
    },
    preIconWrapper: {
      display: 'flex',
      minWidth: 70,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    preIcon: {
      display: 'inline-block',
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
    },

    btnProfile: {
      padding: '2px 15px',
      border: '1px solid #35013f',
      background: 'transparent',
      color: '#35013f',
      textDecoration: 'none',
      borderRadius: '30px',
      fontSize: 12,
      fontFamily: 'Roboto',
      display: 'inline-block',
      marginRight: '10px',
      '&:hover': {
        background: '#35013f',
        color: 'white',
      }
    },
    btnProfileCurrent: {
      borderColor: 'white',
      color: 'white',
      '&:hover': {
        background: 'white',
        color: '#35013f',
      }
    },
    createCurrent: {
      color: 'white',
    },
    secondaryActionRoot: {
      textAlign: 'right',
    },
    checkbox: {
      color: '#cccce0'
    },
    checkboxCurrent: {
      color: 'white'
    },
    hidden: {
      display: 'none',
    },
  })
);

export const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      backgroundColor: "#951556",
      color: "#951556"
      // boxShadow: `0 0 0 0px white`,
      // '&::after': {
      //   position: 'absolute',
      //   top: 0,
      //   left: 0,
      //   width: '100%',
      //   height: '100%',
      //   borderRadius: '50%',
      //   animation: '$ripple 1.2s infinite ease-in-out',
      //   border: '1px solid currentColor',
      //   content: '""',
      // },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0
      }
    }
  })
)(Badge);
