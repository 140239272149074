import {Theme} from "@material-ui/core";

export const stylesXl = (theme: Theme) => (
  {
    root: {
      [theme.breakpoints.up('xl')]: {
        height: '112%',
        backgroundSize: 'cover',
        marginTop: 0,
        marginBottom: 0,
        zIndex: 1,
        padding: theme.spacing(2)
      }
    },
    button: {
      [theme.breakpoints.up('xl')]: {
        textAlign: 'center',
        marginTop: '100px',
        width: '50%',
      }
    },
    img_size: {
      [theme.breakpoints.up('xl')]: {}
    },
    img_text: {
      [theme.breakpoints.up('xl')]: {}
    },
    column_left_row_2_container: {
      [theme.breakpoints.up('xl')]: {
        height: '58vh',
        alignItems: 'stretch',
        paddingTop: '7%',
      }
    },
  }
)
