import { makeStyles, createStyles } from '@material-ui/core'

export const useAppStyles = makeStyles(() => createStyles({
  overlay: {
    display: 'flex',
    background: 'rgba(59,30,63,0.8)',
    color: 'white',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 9999999,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '25px',
    rowGap: '25px',
  },
  connectButton: {
    background: 'white',
    color: 'rgb(59,30,63)',
    border: '2px solid white',
    borderRadius: '30px',
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      background: 'transparent',
      color: 'white',
    }
  }
}
))
