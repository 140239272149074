import {Button} from "@material-ui/core";
import React, {useEffect, useRef} from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CardInterlocutor from "./CardInterlocutor/CardInterlocutor";
import "./interlocutor.scss";
import {InterlocutorType, PropsInterlocutor} from "./type";
import {interlocutorStyle} from "./interlocutor.style";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../../store/redux";
import {formatMessageStudentForAddInOnBoarding, sendMessageToBotPress} from "../../../../common/common";
import Slider from 'react-slick';

const Interlocutor = (props: PropsInterlocutor) => {
  const classes = interlocutorStyle()
  const validButtonRef = useRef<HTMLDivElement>(null);
  const ref = validButtonRef.current;
  const {interlocutorSelected} = useSelector((state: ApplicationState) => state.interlocutor)
  const {idUser} = useSelector((state: ApplicationState) => state.user)
  const dispatch = useDispatch()
  const [canValide, setCanValide] = React.useState(false)
  const {interlocutors, handleClosDialogCarousel} = props;
  useEffect(
    () => {
      if (interlocutorSelected.length > 0) {
        setCanValide(true)
      }
    }, [interlocutorSelected]
  )
  useEffect(() => {
    if (ref) {
      ref.scrollIntoView({behavior: "smooth"});
    }
  }, [canValide])



  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    centerPadding: "100px",
    slidesToShow: 5,
    slidesToScroll: 5,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handlerClick = () => {
    if (interlocutorSelected.length > 0) {
      sendMessageToBotPress(formatMessageStudentForAddInOnBoarding(interlocutorSelected.join(',')), idUser, dispatch)
    }
    handleClosDialogCarousel()
  }

  return (
    <div
      className={`${classes.container_carousel_interlocutor} ${interlocutors.length <= 1 ? classes.container_carousel_interlocutor_center : undefined}`}>
      {
        interlocutors.length <= 1 ? <CardInterlocutor
          key={interlocutors.length + '_CardInterlocutor'}
          interlocutor={interlocutors[0]}
        /> : <Slider {...settings}>
          {interlocutors.map((interlocutor: InterlocutorType) => (
            <CardInterlocutor
              key={interlocutor.title}
              interlocutor={interlocutor}
            />
          ))}
        </Slider>
      }

      {
        canValide && (
          <div className={classes.button} ref={validButtonRef}>
            <Button variant="contained" color="secondary" onClick={handlerClick}>
              Valider
            </Button>
          </div>
        )
      }

    </div>
  );
};

export default Interlocutor;
