import {Epic} from "redux-observable";
import {addMessageInMessageAsync, ChatBotAction} from "./actions";
import {delay, filter, mergeMap, takeWhile} from "rxjs/operators";
import {isOfType} from "typesafe-actions";
import {ADD_MESSAGES_ASYNC} from "./types";
import {from, of} from "rxjs";
import {ApplicationState} from "../index";

export const addMessagesChatBotEpic: Epic<ChatBotAction,
    ChatBotAction,
    ApplicationState> = (action$,
                         store
) =>
    action$.pipe(
        filter(isOfType(ADD_MESSAGES_ASYNC)),
        mergeMap(({payload}) => {
            return from(payload.map((message, index) => ({message, index})))
        }),
        mergeMap(
            ({message, index}) => {
                    return of(addMessageInMessageAsync(message)).pipe(
                        delay((index + 1) * 1500),
                        takeWhile(() => store.value.chatbot.launch)
                    )
            }
        )
    );
