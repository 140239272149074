import {createStyles, makeStyles} from '@material-ui/core'

export const useAppStyles = makeStyles(() => createStyles({
    textWrapper: {
      width: "100%",
      padding: "5px 20px",
      borderRadius: '76px',
      backgroundColor: '#ffffff',
      boxShadow: `0 1px 25px 0 rgba(0, 0, 0, 0.2),
    0 3px 10px -2px rgba(0, 0, 0, 0.12),
    0 3px 11px 0 rgba(0, 0, 0, 0.14)`,
      "@media screen and (min-width: 0px) and (max-width:  600px)": {
        borderRadius: 0,
        position: 'absolute',
        left: 0,
        bottom: 0,
      }
    },
    icon_emojie: {
      transform: 'rotate(60deg)',
      marginRight: '2px'

    },
    responseTextRoot: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: "none",
      },
    },
    responseWrapper: {
      padding: "10px 50px 25px 50px",
      "@media only screen and (width: 360px) and (height: 640px)": {
        position: 'absolute',
        top: -28
      },
      "@media screen and (min-width: 0px) and (max-width:  600px)": {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%'
      }
    },
    form: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    textarea: {
      marginLeft: '17px',
      width: '73%',
      border: 'none',
      marginTop: '12px'
    }
  }
))
