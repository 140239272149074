import {action, ActionType} from "typesafe-actions";
import {
  ADD_MESSAGE_IN_MESSAGE_ASYNC,
  CHANGE_BUTTON_SHOW_BUTTTON,
  ADD_MESSAGES, INIT_MESSAGE_FROM_BOTPRESS_SUCSESS,
  INIT_STATE, START_CHATBOT_FLOW, ADD_MESSAGES_ASYNC, STOP_CHATBOT_FLOW, STOP_SCROLL_TCHAT, START_SCROLL_TCHAT, START_PENDING, STOP_PENDING
} from "./types";
import {MessageObject} from "../../../views/Home/chatBot/ChatBot";

export const initChatbot = (message: MessageObject[]) => action(INIT_STATE, message);

export const initMEssageSucsess = () => action(INIT_MESSAGE_FROM_BOTPRESS_SUCSESS);

export const addMessageInMessageAsync = (message: MessageObject) => action(ADD_MESSAGE_IN_MESSAGE_ASYNC, message);

export const addMessagesAsync = (messages: MessageObject[]) => action(ADD_MESSAGES_ASYNC, messages);

export const addMessages = (messages: MessageObject[]) => action(ADD_MESSAGES, messages);

export const changeShowButton = (value: boolean) => action(CHANGE_BUTTON_SHOW_BUTTTON, value);

export const startChatbot = () => action(START_CHATBOT_FLOW);
export const stopChatbot = () => action(STOP_CHATBOT_FLOW);
export const stopScrollChatbot = () => action(STOP_SCROLL_TCHAT);
export const startScrollChatbot = () => action(START_SCROLL_TCHAT);
export const startPending = () => action(START_PENDING);
export const stopPending = () => action(STOP_PENDING);

const allActions = {
  initChatbot,
  addMessageInMessageAsync,
  addMessages,
  changeShowButton,
  startChatbot,
  initMEssageSucsess,
  addMessagesAsync,
  stopChatbot,
  stopScrollChatbot,
  startScrollChatbot,
  startPending,
  stopPending
};

export type ChatBotAction = ActionType<typeof allActions>
