import React, {Fragment, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../../store/redux";
import {addMessageInMessageAsync, startPending, stopPending} from "../../../../store/redux/chatbot";
import {URL_PASSRELLE_TO_BOTPRESS} from "../../../../config/constant";
import {formatMessageStudentForAddInOnBoarding, responseChatbot} from "../../../../common/common";
import {PCustomQuicklyReplies} from "./styled-component";
import {Grow} from "@material-ui/core";

interface QuickReply {
  title: string;
  payload: string;
}

interface IpropsQuicklyReplies {
  arrayQuicklyReplies: QuickReply[];
}

const QuickReplies = (props: IpropsQuicklyReplies) => {
  const dispatch = useDispatch();
  const { idUser } = useSelector((state: ApplicationState) => state.user);
  const { arrayQuicklyReplies } = props;
  const [showButton, setShowButton] = useState(true)

  const handlerClick = (valueComponent: string, titleComponent: string) => {
    setShowButton(false)
    dispatch(startPending())
    dispatch(
      addMessageInMessageAsync(
        formatMessageStudentForAddInOnBoarding(titleComponent.toLowerCase()))
    );
    axios
      .post(`${URL_PASSRELLE_TO_BOTPRESS}/${idUser}/qualification`, {
        type: "text",
        content: valueComponent
      })
      .then(response => {
        dispatch(stopPending())
        if (response.data) {
          response.data.responses.forEach((d: any) => {
            responseChatbot(d, dispatch);
          });
        }
      });
  };

  return (
    <Fragment>
      {showButton && arrayQuicklyReplies.map(element => (
        <Grow
          in={true}
          style={{ transformOrigin: '0 0 0' }}
          {...({timeout: 1000})}
        >
        <PCustomQuicklyReplies
        key={`PCustomQuicklyReplies_${element.payload}`}
          onClick={
            () => handlerClick(element.title, element.payload)}
        >
          {element.title}
        </PCustomQuicklyReplies>
        </Grow>
      ))}

    </Fragment>
  );
};

export default QuickReplies;
