import { makeStyles, createStyles } from '@material-ui/core'

export const useAppStyles = makeStyles(() => createStyles({
  main: {
    '& em': {
      background: "#951556",
      color: "white"
    }
  },
  img: {
    maxWidth: '40px',
    maxHeight: '40px',
  },
  imgRoot: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#951556",
    boxShadow: `0 0 0 0px red`,
  },
  itemPrimary: {
    color: "#35013f",
    fontSize: "17px",
    fontWeight: 700,
  },
  itemSecondary: {
    fontSize: "16px",
    color: '#000000'
  },
}))
