import styled, {keyframes} from "styled-components";
import {fadeIn} from "react-animations";

const bounceInDownAnimation = keyframes`${fadeIn}`;
const StudentDiv = styled.div`
  animation: 3s ${bounceInDownAnimation};
  flex-direction: row-reverse;
  display: flex;
  p {
    font-family: Roboto, serif;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: 0.36px;
    color: #210227;
    padding: 14px;
    display: inline-block;
    border-radius: 8px;
    border: solid 1px #210227;
    background-color: #ffffff;
    white-space: pre-wrap;
    margin: 6px 6px 26px;
  }
`
export  {StudentDiv}
