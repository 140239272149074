// CoreHeader
import React, { useEffect } from "react";

// Material
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  IconButton,
  MenuItem,
  Menu,
  Avatar,
  ThemeProvider
} from "@material-ui/core";
import { coreHeaderTheme, useAppStyles } from "./coreheaderstyle";
import { useUserAuthentication } from "@dsk-lib/user";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store/redux";
import { UserStatus } from "../../../../store/context/types";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
const screenToDisplay = ['lg', 'md', 'xl']

const CoreHeader = ({ width }: any) => {
  const classes = useAppStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [nameProfil, setNameProfil] = React.useState<string>('Mon profil');
  const [logoProfil, setLogoProfil] = React.useState<string | null>(null);
  const [value, setValue] = React.useState<number>(0);
  const { keycloak } = useUserAuthentication();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickLogout = () => {
    keycloak?.logout();
    closeMenu();
  };

  const handleClickLogin = () => {
    keycloak?.login();
    closeMenu();
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleChange = (_: any, newValue: number) => {
    setValue(newValue);
  };

  const RenderMenuItems = () => {
    if (keycloak?.authenticated) {
      return <MenuItem onClick={handleClickLogout}>Se déconnecter</MenuItem>;
    }
    return null
  };

  const user = useSelector((state: ApplicationState) => state.user);

  useEffect(() => {
    if (user.user.status === UserStatus.Logged) {
      if (user.user.user?.firstName) {
        setNameProfil(`${user.user.user?.firstName} ${user.user.user?.lastName}`)
        setLogoProfil(`${user.user.user?.avatar}`)
      }
    }
  }, [user.user]);
  return (
    <ThemeProvider theme={coreHeaderTheme}>
      <div className={classes.root}>
        <AppBar position="static" className={classes.navbar}>
          <Toolbar>
            <div className={classes.logo}><img src="/images/logo.svg" /></div>
            {screenToDisplay.includes(width) &&
              <Tabs value={value} onChange={handleChange} className={classes.nav} classes={{
                flexContainer: classes.flexNav,
                indicator: classes.indicator
              }}>
                <Tab label="Discussions" id="message" />
              </Tabs>}
            <div>
              {!keycloak?.authenticated ? (<span onClick={handleClickLogin} className={classes.profileBtn}>Connexion</span>) : (
                <>
                  <IconButton
                    aria-label="account of current user"
                    aria-haspopup="true"
                    color="inherit"
                    aria-controls="simple-menu"
                    onClick={handleClick}
                  >
                    <Avatar
                      src={logoProfil !== null ? logoProfil : ''}
                      title={nameProfil}
                    />
                    <span className={clsx({ [classes.profileBtn]: true, [classes.profileBtnLogged]: true })}>{nameProfil}</span>
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted={true}
                    open={Boolean(anchorEl)}
                    onClose={closeMenu}
                  >
                    {RenderMenuItems()}
                  </Menu>
                </>)
              }
            </div>
          </Toolbar>
        </AppBar >
      </div>
    </ThemeProvider>
  );
};

export default withWidth()(CoreHeader);
