import { User } from '../../entity/User';

export enum UserStatus {
  Logged = 'logged',
  Anonymous = 'anonymous',
  Pending = 'pending',
}

export interface UserInterface {
  status: UserStatus
  user: User | null
}
