// Core
import React from "react";
import { useParams } from "react-router-dom";

// Store
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store/redux";
import { changeConversation } from "../../../../../../store/redux/conversation";

// Components
import Messaging from "./components/Messaging";

import { Contact } from "../../../../../../entity/Contact";
import { User } from "../../../../../../entity/User";
import { REACT_APP_ORIA_SSO_ID } from "../../../../../../config/constant";
import { Message } from "../../../../../../entity/Message";

const CoreMessaging = () => {
  // const classes = useAppStyles()
  const { id } = useParams();
  const dispatch = useDispatch();
  const [contact, setContact] = React.useState<Contact>();

  React.useEffect(() => {
    // affecte au store le current id
    if (id !== undefined) {
      dispatch(changeConversation(id));
    }
  }, [id, dispatch]);

  const { currentContact, contacts } = useSelector(
    (state: ApplicationState) => state.conversation
  );
  const { user } = useSelector((state: ApplicationState) => state.user);
  React.useEffect(() => {
    // recupere le current contact
    const contactsFiltered: Contact[] = contacts.filter(c => {
      return c.id === currentContact;
    });
    if (currentContact === REACT_APP_ORIA_SSO_ID && contactsFiltered.length > 0) {
      const messages: Message[] = []
      let firstMessageTime = (new Date()).getTime();
      contactsFiltered.forEach((c: Contact) => {
        const id = `${c.interlocutorRepresentStructure!}-oria`;
        if (messages.find(m => m.id === id) === undefined) {
          const time = (new Date(c.createdAt)).getTime();
          firstMessageTime = firstMessageTime - time < 0 ? firstMessageTime : (new Date(new Date().setHours(0, 0, 0, 0))).getTime();
          messages.push({
            id,
            timestamp: time,
            content: `card:school:${c.interlocutorRepresentStructure!}`,
            senderId: REACT_APP_ORIA_SSO_ID
          });
        }
      })
      messages.unshift({
        id: `${firstMessageTime}`,
        timestamp: firstMessageTime,
        content: "Hello, ces structures n’ont pas encore assigné d’interlocuteurs pour répondre sur ce chat 😕. Par contre, tu peux les contacter directement en utilisant les coordonnées ci-dessous",
        senderId: REACT_APP_ORIA_SSO_ID
      });
      contactsFiltered[0].conversation.messages = messages;
      contactsFiltered[0].baseline = contactsFiltered[0].id !== REACT_APP_ORIA_SSO_ID ? contactsFiltered[0].baseline : 'Ton bot préféré';
    }
    setContact(contactsFiltered[0]);
  }, [currentContact, contacts]);
  return (
    <>
      {contact ? <Messaging contact={contact} user={user.user as User} /> : ""}
    </>
  );
};

export default CoreMessaging;
