import {createStyles, makeStyles} from "@material-ui/core";
import {stylesXl} from "./styles/stylesXl";
import {stylesSm} from "./styles/stylesSm";
import {stylesLg} from "./styles/stylesLg";

export const interlocutorStyle = makeStyles((theme) => {
    return createStyles(
      {
        button: {
          textAlign: 'center',
          paddingTop: '22px'
        },
        container_carousel_interlocutor: {
          ...stylesXl(theme).container_carousel_interlocutor,
          ...stylesSm(theme).container_carousel_interlocutor,
          ...stylesLg(theme).container_carousel_interlocutor
        },
        container_carousel_interlocutor_center: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }

      }
    )
  }
)
