import {Theme} from "@material-ui/core";

export const stylesSm = (theme: Theme) => (
  {
    container_carousel_interlocutor: {
      [theme.breakpoints.up('sm')]: {
/*        width: "877px",
        height: "411px"*/
      }
    }

  }
)
