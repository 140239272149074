// Core
import React from 'react'
import clsx from 'clsx';

import { BrowserRouter as Router, Route, Switch, useLocation, } from "react-router-dom";

// Material
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  Typography,
} from '@material-ui/core'

import { Fullscreen, FullscreenExit } from '@material-ui/icons';

import { useAppStyles } from './coremessagepage.style'
// import ListForClient from './ListForClient'
import CoreMessaging from './components/CoreMessaging/CoreMessaging'

import { CORE_MESSAGE, CORE_MESSAGE_USER, } from '../../../../config/routing'
import ListContact from './components/ListContact/ListContact';
import withWidth from "@material-ui/core/withWidth";
import { screenToDisplay } from "../../../../config/responsive";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store/redux';

const CoreMessagePage = ({ width }: any) => {
  const classes = useAppStyles()
  const location = useLocation();
  const [extendedList, setExtendedList] = React.useState<boolean>(false)
  const isScreenToDisplay = screenToDisplay.includes(width)

  const { loading } = useSelector(
    (state: ApplicationState) => state.conversation
  );

  const handleClickExtend = () => {
    setExtendedList(!extendedList)
  }
  const ExtendIcon = extendedList ? <FullscreenExit /> : <Fullscreen />
  return (
    <Router>
      {loading ? <Box display="flex" flexDirection="row" className={
        clsx({
          [classes.main]: isScreenToDisplay,
          [classes.mainResponsiveScreen]: !isScreenToDisplay,
          [classes.loading]: true,

        })
      }>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3} className={classes.loadingWrapper}>
            <CircularProgress
              variant="indeterminate"
              disableShrink
              size={40}
              thickness={4}
              value={100}
            />
            <Typography>Récuperation des contacts...</Typography>
          </Grid>
        </Grid>
      </Box> : null}
      <Box display="flex" flexDirection="row" className={classes.main}>
        <Drawer
          className={clsx({
            [classes.drawerExtended]: extendedList,
            [classes.drawer]: isScreenToDisplay,
            [classes.drawerResponsive]: !isScreenToDisplay,
          })}
          variant="persistent"
          anchor="left"
          open={true}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {
            isScreenToDisplay &&
            <Box component="span" className={classes.extendIcon} onClick={handleClickExtend}>
              {ExtendIcon}
            </Box>
          }
          <ListContact extendedList={extendedList} />
        </Drawer>

        <Switch>
          <Route path={CORE_MESSAGE_USER}>
            {/* <BoxOria className={classes.mainMessaging}> */}
            {
              isScreenToDisplay && <Box className={clsx(classes.content, {
                [classes.contentShift]: CORE_MESSAGE === location.pathname,
              })}>
                <CoreMessaging />
              </Box>
            }
            {/* </BoxOria> */}
          </Route>
        </Switch>
      </Box>
    </Router>
  )
}

export default withWidth()(CoreMessagePage)
