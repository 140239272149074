import { action, ActionType } from 'typesafe-actions'
import { Contact } from '../../../entity/Contact'
import { Message } from '../../../entity/Message'

export const CHANGE_CONVERSATION = "CHANGE_CONVERSATION"
export const SELECT_CONVERSATION = "SELECT_CONVERSATION"
export const INIT_CONVERSATIONS = "INIT_CONVERSATIONS"
export const INIT_MESSAGES = "INIT_MESSAGES"
export const LOADING_CHAT = "LOADING_CHAT"
export const LOAD_OLD_MESSAGES = "LOAD_OLD_MESSAGES"
export const ADD_MESSAGE_RECEIVE = "ADD_MESSAGE_RECEIVE"
export const ADD_MESSAGE_SEND = "ADD_MESSAGE_SEND"
export const RESET_LAST_MESSAGE = "RESET_LAST_MESSAGE"

export const changeConversation = (id: string) => action(CHANGE_CONVERSATION, id)
export const selectConversation = (id: string) => action(SELECT_CONVERSATION, id)
export const initConversations = (conversations: Contact[]) => action(INIT_CONVERSATIONS, conversations)
export const loadingChat = (isLoading: boolean) => action(LOADING_CHAT, isLoading)
export const initMessages = (id: string, messages: Message[]) => action(INIT_MESSAGES, { id, messages })
export const loadOldMessages = (id: string, messages: Message[]) => action(LOAD_OLD_MESSAGES, { id, messages })
export const addMessageReceive = (message: Message) => action(ADD_MESSAGE_RECEIVE, message)
export const addMessageSend = (message: Message, destinataire: string) => action(ADD_MESSAGE_SEND, {
  message, destinataire
})
export const resetLastMessageConversation = (conversation: string) => action(RESET_LAST_MESSAGE, conversation)

const allActions = {
  changeConversation,
  selectConversation,
  initConversations,
  initMessages,
  loadingChat,
  loadOldMessages,
  addMessageReceive,
  addMessageSend,
  resetLastMessageConversation
}
export type ConversationAction = ActionType<typeof allActions>
