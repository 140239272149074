// Core
import React from 'react'

const NoMessage = () => {

  return (
    <>
      Pas encore de messages
    </>
  )
}

export default NoMessage
