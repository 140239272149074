import {applyMiddleware, combineReducers, Reducer} from "redux";
import {combineEpics, createEpicMiddleware, Epic} from "redux-observable";
import promiseMiddleware from "redux-promise";
import {
  ChatAction,
  chatReducer,
  ChatState,
  initChatEpic,
  receiveMessageEpic,
  sendMessageEpic
} from "./chat";
import {
  changeConversationEpic,
  ConversationAction,
  conversationReducer,
  ConversationState
} from "./conversation";
import {UserAction, userReducer, UserState} from "./user";
import {chatBotReducer} from "./chatbot";
import {IStateChatbot} from "../../views/Home/chatBot/ChatBot";
import {addMessagesChatBotEpic} from "./chatbot/epic";
import {InterlocutorState} from "./interlocutor/type";
import {interlocutorReducer} from "./interlocutor";

export type ApplicationAction = UserAction | ConversationAction | ChatAction;

export const rootEpic: Epic<ApplicationAction,
  ApplicationAction,
  ApplicationState> = combineEpics(
  changeConversationEpic,
  initChatEpic,
  sendMessageEpic,
  receiveMessageEpic,
  addMessagesChatBotEpic
);
export const epicMiddleware = createEpicMiddleware<ApplicationAction,
  ApplicationAction,
  ApplicationState>();

export interface ApplicationState {
  conversation: ConversationState;
  chat: ChatState;
  user: UserState;
  chatbot: IStateChatbot;
  interlocutor: InterlocutorState
}

export const middleware = applyMiddleware(promiseMiddleware, epicMiddleware);
export const rootReducer: Reducer<ApplicationState,
  ApplicationAction> = combineReducers({
  conversation: conversationReducer,
  chat: chatReducer,
  user: userReducer,
  chatbot: chatBotReducer,
  interlocutor: interlocutorReducer
});
