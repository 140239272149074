import React, { useState } from "react";
import "./Buttton.scss";
import { useUserAuthentication } from "@dsk-lib/user";

interface IPropsButton {
  value?: string;
  continuesMessages?: any;
  ref?: any;
}

const CustomButton = (props: IPropsButton) => {
  const { keycloak } = useUserAuthentication();

  const YES = "oui";
  const NO = "non";
  //  const [value, setValue] = useState("");
  const [classButton, setClassButton] = useState([""]);



  const handleClick = (e: any, data: any) => {
    switch (data) {
      case YES:
        setClassButton([`button_${YES}`]);
        keycloak?.login()
        return;
      case NO:
        setClassButton([`button_${NO}`]);
        props.continuesMessages();

        return;
      default:
        console.log(e, data);
        return;
    }
  };

  return (
    <input
      ref={props.ref}
      type="submit"
      className={classButton.join(" ")}
      value={props.value}
      onClick={e => handleClick(e, props.value)}
    />
  );
};

export default CustomButton;
