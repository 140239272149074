import {Dispatch} from "redux";
import {addMessageInMessageAsync} from "../store/redux/chatbot";
import {MessageObject} from "../views/Home/chatBot/ChatBot";
import {v4} from "uuid";
import axios from "axios";
import {URL_FOR_ASK_BOTPRESS} from "../config/constant";

export const responseChatbot = (d: any, dispatch: Dispatch, keycloak?: any) => {
  switch (d.type) {
    case "text":
      const message: MessageObject = {
        type: "text",
        from: "bot",
        content: [d.text]
      };
      dispatch(addMessageInMessageAsync(message));
      return;
    case "custom":
      const message_typing: MessageObject = {
        type: "QuickReplies",
        from: "bot",
        content: {
          text: d.wrapped.text,
          quickReplies: d.quick_replies
        }
      };
      dispatch(addMessageInMessageAsync(message_typing));
      return;
    case "interlocutor":
      const message_interlocutor: MessageObject = {
        type: "carousel",
        from: "bot",
        content: extractionResultInterlocutor(JSON.parse(d.text))
      };
      dispatch(addMessageInMessageAsync(message_interlocutor));
      return;
    case "redirect":
      if (d.destination === "connexion"){
        setTimeout(
          () => {
            keycloak.login()
          },
          3000
        )

      }
      else if (d.destination === "inscription") {
        setTimeout(
          ()=> {
            window.location =  keycloak.createRegisterUrl({
              redirectUri: `${window.location}core/messages`
            })
          }, 3000
        )

      }
      return;
    default:
      return;
  }
};

const extractionResultInterlocutor = (interlocutors: any[]) => {
  return interlocutors.map(interlocutor => {
    return {
      id: `${interlocutor.id}`,
      title: `${interlocutor.title}`,
      address: `${interlocutor.address}`,
      zip: `${interlocutor.zip}`,
      partnaire: interlocutor.partnaire,
      city: `${interlocutor.city}`,
      listChip: interlocutor.listChip
    };
  });
};

export const createUuid = () => {
  return v4()
};

export const sendMessageToBotPress = (message: MessageObject, idUser: string | null, dispatch: Dispatch, keycloak?: any) => {

  axios.post(URL_FOR_ASK_BOTPRESS(idUser), {
    type: 'text',
    content: message.content[0]
  })
    .then(
      response => {
        if (response.data) {
          response.data.responses.forEach((d: any) => {
            responseChatbot(d, dispatch, keycloak)
          })
        }
      }
    )

};

export const formatMessageStudentForAddInOnBoarding = (message: string): MessageObject => {
  return {
    type: "text",
    from: "student",
    content: [message]
  };
};
