import {Theme} from "@material-ui/core";

export const stylesLg = (theme: Theme) => (
  {
    'text-oria-p': {
      '& p': {
        [theme.breakpoints.up('lg')]: {
          fontSize: '15px',
        }
      }
    }
  }
)
