import { Reducer } from "redux";
import { ChatBotAction } from "./actions";
import {
  ADD_MESSAGE_IN_MESSAGE_ASYNC,
  ADD_MESSAGES,
  CHANGE_BUTTON_SHOW_BUTTTON,
  INIT_MESSAGE_FROM_BOTPRESS_SUCSESS,
  INIT_STATE,
  START_CHATBOT_FLOW, START_PENDING,
  START_SCROLL_TCHAT,
  STOP_CHATBOT_FLOW, STOP_PENDING,
  STOP_SCROLL_TCHAT
} from "./types";
import { IStateChatbot, MessageObject } from "../../../views/Home/chatBot/ChatBot";

const initialState: IStateChatbot = {
  messages: [],
  isArrived: false,
  button_yes: false,
  button_no: false,
  messageAsync: [],
  showButton: true,
  isStudent: false,
  launch: false,
  activeScroll: true,
  pending: false
};

const reducer: Reducer<IStateChatbot, ChatBotAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case INIT_STATE:
      return {
        ...state,
        payload: action.payload
      };
    case INIT_MESSAGE_FROM_BOTPRESS_SUCSESS:
      return {
        ...state
      };
    case ADD_MESSAGE_IN_MESSAGE_ASYNC:
      const newMessageAsync = [...state.messageAsync, action.payload].reduce((acc: MessageObject[], current: MessageObject) => {
        const x = acc.find((item: any) => item.id === current.id);
        if (!x || current.id === undefined) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [])
      return {
        ...state,
        messageAsync: newMessageAsync
      };
    case ADD_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };
    case CHANGE_BUTTON_SHOW_BUTTTON:
      return {
        ...state,
        showButton: action.payload
      };
    case START_CHATBOT_FLOW:
      return {
        ...state,
        launch: true
      };
    case STOP_CHATBOT_FLOW:
      return {
        ...state,
        launch: false
      };
    case START_SCROLL_TCHAT:
      return {
        ...state,
        activeScroll: true
      };
    case STOP_SCROLL_TCHAT:
      return {
        ...state,
        activeScroll: false
      };
    case START_PENDING:
      return {
        ...state,
        pending: true
      };
    case STOP_PENDING:
      return {
        ...state,
        pending: false
      }
    default:
      return state;
  }
};

export { reducer as chatBotReducer };
