import styled from "styled-components";

const BoxOria = styled.div`
  width: 370px;
  height: 240px;
  border-radius: 4px;
  box-shadow: 3px 2px 24px 0 rgba(0, 0, 0, 0.09);
  background-color: rgba(255, 255, 255, 0.92);
`

export {BoxOria}
