import {Avatar, Box, CardContent, CardHeader, Chip, IconButton, Typography, Grid} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import React, {useState} from "react";
import styled from "styled-components";
import places from "../assets/ic-lieu.svg";
import {CardPropsInterlocutor} from "../type";
import {useDispatch} from "react-redux";
import {addInterlocutor, removeInterlocutor} from "../../../../../store/redux/interlocutor";
import {cardInterlocutorStyle} from "./cardInterlocutor.style";

export const DiplomaComponentStyle = styled.p`
  background-color: #d7aac2;
  border-radius: 20px;
  text-align: center;
  padding: 8px;
  font-weight: 900;
  color: #951556;
  height: 12px;
  padding-bottom: 26px;
  flex-shrink: 0;
`;

// import {Border, BoxOria} from "../../../../styled-component-oria";

// const DivSpace = styled.div`
// padding-top: 24px;
// `
//
// const TitleSchoolCardOria = styled.p`
//  letter-spacing: 0.34px;
//  font-size: 16px;
//   font-family: Bitter;
//   font-weight: 700;
//   height: 21px;
//   width: 192px;
// `
//
// const LogoOrProfilOriaCard = styled.div`
//     padding-top: 34px;
//     padding-left: 50px;
// `

const CardInterlocutor = (props: CardPropsInterlocutor) => {
  const {interlocutor} = props;
  const classes = cardInterlocutorStyle();
  const dispatch = useDispatch();
  const [validateCardStatus, setValidateCardStatus] = useState<boolean>(false);
  const [idInterlocutor] = useState(interlocutor.id);
  const handleClick = () => {
    if (validateCardStatus) {
      setValidateCardStatus(false);
      dispatch(removeInterlocutor(idInterlocutor))

    } else {
      setValidateCardStatus(true);
      dispatch(addInterlocutor(idInterlocutor))

    }

  };

  return (
    <Box
    >
      <Card className={classes.root} onClick={handleClick}>
        <CardHeader
          classes={{
            title: classes.titleSchool
          }}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {interlocutor.title.substring(0, 1)}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              {validateCardStatus ? (
                <CheckCircleIcon htmlColor="#951556"/>
              ) : (
                <CheckCircleOutlineIcon htmlColor="#e9b5d2"/>
              )}
            </IconButton>
          }
          title={interlocutor.title}
        />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs>
              <img src={places} className={classes.img} alt=""/>
            </Grid>
            {interlocutor.address && <Grid item xs={6}>
                <Typography variant="subtitle1" color="textSecondary" component="p">
                  {/*{interlocutor.address}*/}
                  {interlocutor.zip} {interlocutor.city}
                </Typography>
            </Grid>}
            <Grid item xs>
              {interlocutor.partner && (
                <Chip
                  classes={
                    {
                      label: classes.contentTextSchool
                    }
                  }
                  size="small"
                  label="Partnaire"
                  color="primary"
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Box display="flex" justifyContent="center"  padding={1.5}>
          {interlocutor.listChip.map(element => (
            <Chip
              key={`compenent_${element}`}
              size="small"
              label={element}
              classes={{label: classes.textChip, root:classes.rootChip   }}
              color="secondary"
            />

          ))}
        </Box>
        {/*      <CardActions className={classes.bottom} >
        <Button size="small">voir la fiche</Button>
      </CardActions>*/}
      </Card>
    </Box>

  )
    ;
};

export default CardInterlocutor;
