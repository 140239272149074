export const HOME = "/"

export const CORE = "/core"
export const CORE_MESSAGE = "/core/messages"
export const CORE_MESSAGE_USER = "/core/messages/:id"

export const CORE_PROFILE = "/core/profile"
export const CORE_PROFILE_USER = "/core/profile/:id"

export const INTERLOCUTOR = "/interlocutor"

interface StringMap {
    [key: string]: string;
}

export const getUrl = (route: string, params: StringMap): string => {
  Object.keys(params).forEach(k => {
    const replace = `:${k}`;
    const re = new RegExp(replace, "gi");

    route = route.replace(re, params[k] as string);
  })
  return route
}
