import styled from "styled-components";

const Border = styled.div`
  height: 240px;
  width: 370px;
  background-color: rgba(255, 255, 255, 0.92);
  border-radius: 4px;
  box-shadow: 3 2px 24px 0px rgba(0, 0, 0, 0.09);
`;

export {Border}
