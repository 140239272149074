import { makeStyles, createStyles } from '@material-ui/core'

export const useAppStyles = makeStyles(() => createStyles({
  main: {
    padding: `5px`,
  },
  img: {
    maxWidth: '40px'
  },
  detail_name: {
    margin: 0,
  },
  list: {
    listStyle: "none",
  },
  imgHeader: {
    maxWidth: '40px'
  },
  header: {
    background: "radial-gradient(circle at 20% 50%, #d3156a, #3b1d3f 59%)",
    color: "white",
    height: "85px",
    padding: "15px 30px"
  },
  name: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  presentation: {
    padding: '0 10px',
    minWidth: '0',
  },
  baseline: {
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  searchRoot: {
    height: "40px",
    color: '#FFF',
    '&:hover': {
      color: "#FFF",
    },
    '& .MuiInputBase-input:hover': {
      background: "none",
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: "#FFF",
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: "20px",
      border: "2px solid #FFF",
    },
    '& ::placeholder': {
      color: "#FFF"
    }
  },
  tools: {
    '& > *': {
      marginLeft: '15px'
    }
  },
  // core messaging
  wrapperMessaging: {
    background: "#fafafc",
  },
  mainMessagingWrapper: {
    padding: "20px 60px",
    overflowY: "scroll",
    position: 'relative',
  },
  // block day
  blockDay: {
    border: "none",
    "& legend": {
      textAlign: "center",
      color: "#35013f",
      fontSize: "15px",
      fontStyle: "italic"
    }
  },
  loadingMessages: {
    display: 'block',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    height: 10,
    zIndex: 8888,
    textAlign: 'center',
    paddingTop: 15,
  },
}
))
