// Core
import React, { useRef } from "react";

import { Contact } from "../../../../../../../entity/Contact";
import { Message } from "../../../../../../../entity/Message";
import { User } from "../../../../../../../entity/User";
import { Message as MessageItem, MessageType } from "./components/Message";
import { useAppStyles } from "../coremessaging.style";
import moment from "moment";
import "moment/locale/fr";
import { displayName } from "../../../../../../../services/helper";

interface MessageListProps {
  contact: Contact;
  me: User;
}

interface DayGroup {
  date: Date;
  interlocuteurs: Interlocuteur[];
}
interface Interlocuteur {
  contact: User;
  message: Message;
}

const MessageList = (props: MessageListProps) => {
  const [dayGroup, setDayGroup] = React.useState<DayGroup[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const sortByDate = (a: Message, b: Message) => {
    return a.timestamp - b.timestamp;
  };
  React.useEffect(() => {
    const byDate: { [key: string]: { date: Date; messages: Message[] } } = {};
    props.contact.conversation.messages.sort(sortByDate).forEach(m => {
      const date = new Date(m.timestamp);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const formatDate = `${date.getTime()}`;
      if (byDate[formatDate] === undefined) {
        byDate[formatDate] = { date, messages: [] };
      }
      byDate[formatDate].messages.push(m);
    });
    const newBlocks: DayGroup[] = [];
    Object.keys(byDate)
      .sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10))
      .forEach(d => {
        const block = {
          date: byDate[d].date,
          interlocuteurs: byDate[d].messages.map((m: Message) => {
            return {
              message: m,
              contact: m.senderId === props.me.id ? props.me : props.contact
            };
          })
        };
        newBlocks.push(block);
      });
    setDayGroup(newBlocks);
  }, [props.contact, props.contact.conversation.messages.length, props.me]);

  React.useEffect(() => {
    setTimeout(()=> {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500)
  }, [props.contact.conversation.messages, props.contact.conversation.messages.length]);
  return (
    <>
      {dayGroup.map((b, ind) => {
        return <DayGroup key={`message-day-group-${ind}`} group={b} />;
      })}
      <div ref={messagesEndRef} />      
    </>
  );
};

interface DayGroupProps {
  group: DayGroup;
}
const DayGroup = (props: DayGroupProps) => {
  const classes = useAppStyles();
  const formatMessage = (content: string | string[]) => {
    if (typeof content !== 'string') {
      const messages = content.map((c) => {
        let message = { type: MessageType.TEXT, content: c }
        if (c.split(':')[0] === 'card') {
          message = {
            type: MessageType.CARD, content: c.split(':').splice(1).join(':') as string
          };
        }
        return message;
      })
      return messages;
    } else {
      let message = { type: MessageType.TEXT, content }
      if (content.split(':')[0] === 'card') {
        message = {
          type: MessageType.CARD, content: content.split(':').splice(1).join(':')
        };
      }
      return [message];
    }
  }
  return (
    <>
      <fieldset className={classes.blockDay}>
        <legend>{moment(props.group.date).format("dddd D MMMM YYYY")}</legend>
        {props.group.interlocuteurs.map((i, ind) => {

          return (
            <MessageItem
              key={`message-${ind}-${i.contact.id}`}
              author={displayName(i.contact.firstName, i.contact.lastName)}
              messages={formatMessage(i.message.content)}
              avatarUrl={i.contact.avatar}
            />
          );
        })}
      </fieldset>
    </>
  );
};

export default MessageList;
