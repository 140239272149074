// Core
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import CoreMessagePage from './components/CoreMessagePage/CoreMessagePage'
import CoreProfilePage from './components/CoreProfilePage/CoreProfilePage'

import {
  CORE,
  CORE_MESSAGE,
  CORE_PROFILE,
  CORE_PROFILE_USER
} from '../../config/routing'
import CoreHeader from './components/CoreHeader/CoreHeader';
import withWidth from "@material-ui/core/withWidth";
import { useUserAuthentication } from '@dsk-lib/user';
import { useAppStyles } from './corestyle';


const Core = () => {
  const classes = useAppStyles()
  const {
    keycloak,
  } = useUserAuthentication();

  const handleConnect = ()=> {
    keycloak.login();
  }
  return (
    <>
      <CoreHeader />
      <Router>
        <Switch>
          <Route path={CORE_MESSAGE}>
            <CoreMessagePage />
          </Route>
          <Route path={CORE_PROFILE}>
            <Router>
              <Switch>
                <Route path={CORE_PROFILE_USER}>
                  <CoreProfilePage />
                </Route>
              </Switch>
            </Router>
          </Route>
          <Route path={CORE}>
            <div>This is dashboard</div>
          </Route>
        </Switch>
      </Router>
      {!keycloak.authenticated ? <div className={classes.overlay}>
        <div>Vous devez être connecté pour accéder à cette partie.</div>
        <div><button className={classes.connectButton} onClick={handleConnect}>Je me connecte</button></div>
      </div> : null}
    </>
  )
}

export default withWidth()(Core)
