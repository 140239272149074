import {useQuery} from "@apollo/react-hooks";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import CSSTransition from "react-transition-group/CSSTransition";
import {flowChatbot} from "../../../services/graphQlQuery";
import {ApplicationState} from "../../../store/redux";
import {addMessages, addMessagesAsync, startChatbot} from "../../../store/redux/chatbot";
import {DivOnBoardingOria, StudentDiv} from "../../../styled-component-oria";
import {isStudent} from "../../MessagingResponse/MessagingResponse";
import CustomButton from "./Button";
import "./ChatBot.scss";
import Interlocutor from "./interlocutor/Interlocutor";
import QuickReplies from "./quickReplies/QuickReplies";
import {chatBotStyle} from "./styles/Chatbot.style";
import {Grow, Box, Dialog, DialogContent} from '@material-ui/core';
import {Spin} from 'antd';
import withWidth from "@material-ui/core/withWidth";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {theme} from '../../App/App'
import {screenToDisplay} from "../../../config/responsive";

// const media = 'media'
const text = "text";
const picture = "picture";
const button = "button";
const quickReplies = "QuickReplies";
const carousel = "carousel";

export interface MessageObject {
  id?: number;
  type: string;
  from?: string;
  content: any;
}

export interface IPropsChatbot {
  showButton: (value: boolean) => void;
  connectionChecked: boolean;
  width: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export interface IStateChatbot {
  messages: MessageObject[];
  isArrived: boolean;
  button_yes: boolean;
  button_no: boolean;
  messageAsync: MessageObject[];
  showButton: boolean;
  isStudent: boolean;
  launch: boolean;
  activeScroll: boolean;
  pending: boolean;

}

const ChatBot = (props: IPropsChatbot) => {
  const dispatch = useDispatch();
  const classes = chatBotStyle();
  const {data} = useQuery(flowChatbot);
  const [dialogCarousel, setDialogCarousel] = useState<boolean>(true)

  const handleClosDialogCarousel = () => {
    setDialogCarousel(false)
  }

  const {chatbot} = useSelector<ApplicationState, ApplicationState>(
    state => state
  );
  // const [counter, setCounter] = useState(0)
  const messageRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<any>(null);
  const ref = messageRef.current;
  const {pending} = chatbot

  const modalTheme = createMuiTheme({
    ...theme,
    overrides: {
      MuiDialogContent: {
        root: {
          backgroundColor: "transparent",
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }
      },
      MuiDialog: {
        paper: {
          backgroundColor: "transparent"
        }
      }
    }
  })

  function createMarkup(s: string) {
    return {__html: s};
  }

  const onResize = (_: number, height: number) => {
    if (ref && ref.parentElement && ref.parentElement.parentElement && chatbot.activeScroll) {
      ref?.parentElement?.parentElement.scrollTo({top: height, behavior: "smooth"});
    }
    if (!screenToDisplay.includes(props.width)) {
      if (ref && ref.parentElement && ref.parentElement && chatbot.activeScroll) {
        ref?.parentElement.scrollTo({top: height, behavior: "smooth"});
      }
    }
  };

  const continuesMessages = (): void => {
    props.showButton(false);
    dispatch(addMessagesAsync(chatbot.messages.slice(4)));
  };

  useEffect(() => {
    if (data) {
      const {getFlowPrimary} = data;
      dispatch(startChatbot());
      dispatch(addMessages(getFlowPrimary));
      dispatch(addMessagesAsync(getFlowPrimary.slice(0, 4)));
    }
  }, [data]);

  return (
    <div ref={messageRef}>

      {chatbot.messageAsync.map(
        (message: MessageObject, indexMessageAsync: number) => {
          switch (message.type) {
            case text:
              return message.from !== isStudent ? (
                <DivOnBoardingOria className={classes["text-oria-p"]}>
                  {message.content.map((element: any) => (
                      <p key={element} dangerouslySetInnerHTML={createMarkup(element)}/>

                  ))}
                </DivOnBoardingOria>
              ) : (
                <StudentDiv>
                  {message.content.map((element: any) => (
                    <p key={element}> {element} </p>
                  ))}
                </StudentDiv>
              );
            case picture:
              return message.content.map((element: any, id: number) => (
                <img key={id} src={element} style={{maxWidth: '60%'}} alt=""/>
              ));
            case button:
              return (
                <div
                  key={`${message.type}_${indexMessageAsync}`}
                  className={props.connectionChecked ? "button_parent" : ""}
                >
                  {message.content.map((element: any, id: number) => (
                    <CustomButton
                      key={id}
                      value={element}
                      ref={buttonRef}
                      continuesMessages={continuesMessages}
                    />
                  ))}
                </div>
              );
            case quickReplies:
              return (
                <Fragment key={`${message.type}_${indexMessageAsync}`}>
                  <div>
                    <CSSTransition
                      key={`transition_${indexMessageAsync}`}
                      in={true}
                      timeout={5000}
                      appear={true}
                      classNames="oria"
                    >
                      <div className={classes["text-oria-p"]}>
                        <Grow
                          in={true}
                          style={{transformOrigin: '0 0 0'}}
                          {...({timeout: 1000})}
                        >
                          <p>{message.content.text}</p>
                        </Grow>
                      </div>
                    </CSSTransition>
                  </div>

                  <CSSTransition
                    key={`transition_${indexMessageAsync}`}
                    in={true}
                    timeout={5000}
                    appear={true}
                    classNames="oria"
                  >
                    <Grow
                      in={true}
                      style={{transformOrigin: '0 0 0'}}
                      {...({timeout: 1000})}
                    >
                      <QuickReplies
                        arrayQuicklyReplies={message.content.quickReplies}
                      />
                    </Grow>
                  </CSSTransition>
                </Fragment>
              );
            case carousel:
              return (
                <ThemeProvider theme={modalTheme}>
                  <Dialog
                    open={dialogCarousel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    fullScreen={true}
                    maxWidth={props.width}
                  >
                    <DialogContent>
                      <Interlocutor
                        handleClosDialogCarousel={handleClosDialogCarousel}
                        interlocutors={message.content}/>
                    </DialogContent>
                  </Dialog>
                </ThemeProvider>

              );
            default:
              return <div/>;
          }
        }
      )}
      {
        pending && <Box display="flex" justifyContent="space-between" width={185}>
            <Spin style={{color: "white"}}/>
            <span style={{color: "white", fontFamily: 'Roboto'}}>
          Oria est en train d’écrire
        </span>
        </Box>
      }


      <ReactResizeDetector handleHeight={true} onResize={onResize}/>
    </div>

  );
};

export default withWidth()(ChatBot);
