import { makeStyles, createStyles, Theme, createMuiTheme } from '@material-ui/core'

export const coreHeaderTheme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        borderRadius: 2,
        '&:hover': {
          backgroundColor: '#f3f3f8',
        }
      },
      label: {
        fontSize: 14,
      }
    },
  },
});

export const useAppStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1
  },
  nav: {
    flexGrow: 1,
    alignSelf: 'stretch',
    padding: '0 100px'
  },
  flexNav: {
    height: '100%',
  },
  indicator: {
    height: '5px',
    backgroundColor: '#951556',
  },
  navbar: {
    background: "#FFFFFF",
    color: "#35013f",
    maxHeight: '80px'
  },
  profileBtn: {
    cursor: 'pointer',
  },
  profileBtnLogged: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      padding: 5,
    },
  }
}
))
