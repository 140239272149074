import React from 'react'
// Material
import { Box, Icon } from '@material-ui/core';
import { useAppStyles } from '../../../profile.style'

interface ProfilItemProps {
  icon: string
  title: string
  value: string
}
export const ProfileItem = (props: ProfilItemProps) => {
  const classes = useAppStyles()
  const { icon, value, title } = props
  if (value.length > 0) {
    return (
      <Box display="flex" flexDirection="row">
        <Box flex={1}>
          <Icon>{icon}</Icon>
        </Box>
        <Box flex={6}>
          <h5 className={classes.itemTitle}>{title}</h5>
          <p>{value}</p>
        </Box>
      </Box>
    )
  }
  return (<></>)
}
