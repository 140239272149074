// Core
import React from 'react'

// Store
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../../store/redux'
import { changeConversation } from '../../../../../../store/redux/conversation'

// Material
import {
  AppBar,
  Box,
  createStyles,
  Dialog,
  IconButton,
  List,
  makeStyles,
  Slide,
  Toolbar,
} from '@material-ui/core'

import { Contact } from '../../../../../../entity/Contact'

import ContactItem from './components/ContactItem/ContactItem'

import ItemHeader from './components/ItemHeader/ItemHeader'
import CloseIcon from "@material-ui/icons/Close";
import CoreMessaging from "../CoreMessaging/CoreMessaging";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { screenToDisplay } from "../../../../../../config/responsive";
import withWidth from "@material-ui/core/withWidth";
import { REACT_APP_ORIA_SSO_ID } from '../../../../../../config/constant'

// const sortAlphabetically = (a: Contact, b: Contact) => {
//   if (`${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}` < `${b.firstName.toLowerCase()} ${b.lastName.toLowerCase()}`) { return -1; }
//   if (`${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}` > `${b.firstName.toLowerCase()} ${b.lastName.toLowerCase()}`) { return 1; }
//   return 0;
// }

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const sortChrono = (a: Contact, b: Contact) => {
  const tabA = [...a?.conversation?.messages];
  const aDate: Date = tabA && tabA.length > 0 ? new Date(tabA[tabA.length - 1].timestamp) : a.createdAt;
  const tabB = [...b?.conversation?.messages];
  const bDate: Date = tabB && tabB.length > 0 ? new Date(tabB[tabB.length - 1].timestamp) : b.createdAt;

  return bDate.getTime() - aDate.getTime()
}

interface ListContactProps {
  extendedList: boolean;
  width: string;
}

const ListContact = ({ extendedList, width }: ListContactProps) => {
  const [open, setOpen] = React.useState(false);
  const classes = makeStyles(() => createStyles({
    modale: {
      color: '#000000',
      background: '#ffffff'
    }
  }))()
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const convervationSelector = useSelector((state: ApplicationState) => state.conversation)

  const contactOria: Contact[] = convervationSelector.contacts
    .filter((c: Contact) => { return c.id === REACT_APP_ORIA_SSO_ID })
  const contactSorted: Contact[] = convervationSelector.contacts
    .filter((c: Contact) => { return c.id !== REACT_APP_ORIA_SSO_ID })
    .sort(sortChrono)
  /** Store */
  const dispatch = useDispatch()

  const isScreenToDisplay = screenToDisplay.includes(width)
  const handleSelectContact = (c: Contact) => {
    dispatch(changeConversation(c.id));
    if (!isScreenToDisplay) {
      handleClickOpen();
    }
  }

  return (
    <div>
      {contactOria.length > 0 ? (
        <List>
          <ContactItem
            key={`oria-bot`}
            isExtended={extendedList}
            isBot={true}
            isCurrent={convervationSelector.currentContact === REACT_APP_ORIA_SSO_ID}
            contact={contactOria[0]}
            handleClickContact={() => { handleSelectContact(contactOria[0]) }}
          />
        </List>
      ) : null}
      <ItemHeader text={`Chat`} baseline={`(${contactSorted.length} conversations)`} />
      <List>
        {
          contactSorted.map((c, index) => <ContactItem
            key={`interlocuteur-chat-${index}`}
            isExtended={extendedList}
            isBot={false}
            isCurrent={c.id === convervationSelector.currentContact}
            contact={c}
            handleClickContact={() => { handleSelectContact(c); }}
          />)
        }
      </List>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar position="static" >
          <Toolbar className={classes.modale}>
            <IconButton edge="start" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <Box
            flexGrow={1}
          >
            <CoreMessaging />
          </Box>

        </Box>

      </Dialog>
    </div>
  )
}

export default withWidth()(ListContact)
