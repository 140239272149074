import { makeStyles, createStyles, Theme } from '@material-ui/core'

const drawerWidth = '240px';
export const useAppStyles = makeStyles((theme: Theme) => createStyles({
  contentList: {
    padding: `5px`,
  },
  main: {
    width: '100vw',
    height: 'calc(100vh - 64px)',
    overflow: 'hidden'
  },
  mainResponsiveScreen: {
    width: '100%',
    height: '100%'
  },
  list: {
    padding: '5px',
    overflowY: "auto",
    background: 'lightgrey',
    minWidth: '370px'
  },
  mainMessaging: {
    width: "100%"
  },
  content: {
    flexGrow: 1,

    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  drawerResponsive: {
    width: "100%"
  },
  drawer: {
    // minWidth: drawerWidth,
    flexShrink: 0,
    boxShadow: "0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
    maxWidth: '25%',
  },
  drawerPaper: {
    background: "#f3f3f8",
    position: "relative",
  },
  drawerExtended: {
    minWidth: "50%",
    maxWidth: '60%',
  },
  extendIcon: {
    textAlign: "right"
  },
  loading: {
    position: 'absolute',
    zIndex: 99999,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.7)'
  },
  loadingWrapper: {
    textAlign: 'center',
    color: 'white',
    fontSize: 26,
  }
}
))
