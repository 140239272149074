import {Box} from "@material-ui/core";
import ChatBot from "../../chatBot/ChatBot";
import {detect} from 'detect-browser';
import './xsScreen.scss'
import React from "react";
import clsx from "clsx";
interface XsScreenProps {
  changeButton: (value: boolean) => void;
  connectionChecked: boolean;
  getButton: () => JSX.Element;
}
const XsScreen =  ({changeButton, connectionChecked, getButton}: XsScreenProps) => {
  const browser = detect()
  const isSamsungBrowser = browser?.name === 'samsung'
  const isSafariBrowser = browser?.name === 'ios';


    return (
      <>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <Box component='div' className={clsx({
          "grid-container": !isSamsungBrowser,
          "grid-container-browser": isSamsungBrowser,
          "grid-container-ios": isSafariBrowser
        })} >
          <Box
            component='div'
            className="header"
          >
            <img className="imgLogo" src={require("../../asset/logo.svg")} width={50} alt=""/>
          </Box>
          <Box
            component='div'
            className="body"
          >
            <ChatBot
              showButton={changeButton}
              connectionChecked={connectionChecked}
            />
          </Box>
          <Box
            component='div'
            className="footer"
          >
            {(getButton)()}
          </Box>

        </Box>
      </>
    );
  }

export default XsScreen
