import React from "react";
import {Button, createStyles, Theme, withStyles} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const ColorButton = withStyles((theme: Theme) => {
    return (
      {
        root: {
          color: theme.palette.getContrastText('#951556'),
          backgroundColor: '#951556',
          borderRadius: '100px',
          '&:hover': {
            backgroundColor: '#951556',
          }
        },
      })
  }
)(Button);

const useStyles = makeStyles(
  () => createStyles({
    homeStyle: {
      backgroundColor: '#951556',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      width: '190px',
      paddingTop: '11px',
      fontWeight: 800
    },
    messageStyle: {
      width: '80px',
      height: '30px',
      borderRadius: '100px',
      backgroundColor: '#e92977',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      marginBottom: '18px',
      '&:hover': {
        color: "#fffff",
        backgroundColor: "#e92977"
      }
    }
  })
)

interface ICustomButton {
  text: string,
  class: string,
  click?: () => void
}

const ButtonCustom = (props: ICustomButton) => {
  const classes = useStyles()
  if (props.class === "homeStyle") {
    return (
      <ColorButton onClick={props.click} variant="contained" color="primary">
        <p className={classes.homeStyle}>{props.text}</p>
      </ColorButton>
    )
  } else {
    return (
      <input type="submit" className={classes.messageStyle}/>
    )
  }

}

export default ButtonCustom
