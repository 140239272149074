import {Theme} from "@material-ui/core";

export const stylesLg = (theme: Theme) => (
  {
    [theme.breakpoints.up('lg')]: {
      width: "1012px",
      height: "411px"
    }
  }
)
