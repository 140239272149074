// Core
import React from "react";
// Material
import { useAppStyles } from "./messagingresponse.style";
import ButtonCustom from "../Home/ButtonCustom";
import { useDispatch } from "react-redux";
import { stopChatbot } from "../../store/redux/chatbot";

interface ResponseTextProps {
  submit(a: string): any;
}

const ResponseText = (props: ResponseTextProps) => {
  const dispatch = useDispatch();
  const classes = useAppStyles();
  const [message, setMessage] = React.useState<string>("");
  const [lengthMessage, setLengthMessage] = React.useState<number>(255);

  const handleChange = (e: any) => {
    dispatch(stopChatbot());
    setLengthMessage(255 - e.target.value.length);
    setMessage(e.target.value);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (message.trim() !== "") {
      props.submit(message.trim());
      setMessage("");
      setLengthMessage(255);
    }
  };
  const handleKeyUp = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case "Enter":
        if (message.trim() !== "") {
          props.submit(message.trim());
          setMessage("");
          setLengthMessage(255);
        }
        return;
      case "Backspace":
        if (lengthMessage > 255) {
          setLengthMessage(lengthMessage + 1);
        }

        return;
      case "ArrowUp":
        // TODO handle history
        return;
      default:
        return;
    }
  };
  return (
    <div className={classes.textWrapper}>
      <form onSubmit={handleSubmit} className={classes.form} autoComplete="off">
        <textarea
          className={classes.textarea}
          id="outlined-dense"
          value={message}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          placeholder={"Ton message..."}
          cols={60}
          rows={2}
          maxLength={lengthMessage}
        />
        {lengthMessage}/255
{/*        <div>
          <SentimentVerySatisfiedIcon />
        </div>
        <div className={classes.icon_emojie}>
          <LinkIcon />
        </div>*/}
        <ButtonCustom class="messageStyle" text="Envoyer" />
      </form>
    </div>
  );
};

export default ResponseText;
