// Polyfill
// apollo
import { UserAuthenticationProvider } from "@dsk-lib/user";
import "antd/dist/antd.css";
// Core
import React from "react";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  KEYCLOAK_CONFIG,
  KEYCLOAK_PROVIDER_INIT_CONFIG
} from "./config/keycloak";
import "./index.scss";
// ServiceWorker
import * as serviceWorker from "./serviceWorker";
import store from "./store/configureStore";
// Component
import App from "./views/App/App";

ReactDOM.render(
  <UserAuthenticationProvider
    keycloakInitOptions={KEYCLOAK_PROVIDER_INIT_CONFIG}
    keycloakConfig={KEYCLOAK_CONFIG}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </UserAuthenticationProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
