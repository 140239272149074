// Core
import { ApolloProvider } from "@apollo/react-hooks";
import { useUserAuthentication } from "@dsk-lib/user";
import { CssBaseline } from "@material-ui/core";
// Material
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../../assets/common.scss";
import { createUuid } from "../../common/common";
import { CORE, HOME, INTERLOCUTOR } from "../../config/routing";
import "../../index.scss";
import Lilsis from "../../services/lilsis";
import Passerelle from "../../services/passerelle";
import { UserStatus } from "../../store/context/types";
// Context
// store
import { ApplicationState } from "../../store/redux";
import { initChat } from "../../store/redux/chat";
// import {setMe} from '../../store/redux/user'
// ACTIONS
import { initConversations, loadingChat } from "../../store/redux/conversation";
import { setMe, setUserId } from "../../store/redux/user";
// Styles
import Core from "../Core/Core";
import CustomCarousel from "../Home/chatBot/interlocutor/CustomCarousel/CustomCarousel";
import { mockInterlocutor } from "../Home/chatBot/interlocutor/type";
import Onboarding from "../Home/chatBot/Onboarding";
// Pages
import Home from "../Home/Home";

export let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#951556'
    },
    secondary: {
      main: '#e9b5d2'
    }
  },
});

theme = responsiveFontSizes(theme)

const App = () => {
  const {
    keycloak,
    fetchWithCredentials
  } = useUserAuthentication();
  const user = useSelector((state: ApplicationState) => state.user);
  const dispatch = useDispatch();
  const IDUSER = "iduser";
  const idUserFromLocalStorage = localStorage.getItem(IDUSER);

  useEffect(() => {
    if (keycloak?.authenticated) {
      const idSsoUserFromkeycloak = keycloak.tokenParsed!.sub!;
      dispatch(setUserId(idSsoUserFromkeycloak));
      if (
        idSsoUserFromkeycloak !== idUserFromLocalStorage &&
        idUserFromLocalStorage !== null
      ) {
        localStorage.setItem(IDUSER, idSsoUserFromkeycloak);
        Passerelle.setSsoId(
          idSsoUserFromkeycloak,
          idUserFromLocalStorage
        ).catch(err => console.error(err));
      }
    } else {
      // if (idUserFromLocalStorage) {
      //   dispatch(setUserId(idUserFromLocalStorage));
      // } else {
      if (window.location.pathname === '/') {
        console.info('Unknown User Id refresh')
        const newIdUser = createUuid();
        dispatch(setUserId(newIdUser));
        localStorage.setItem(IDUSER, newIdUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak?.authenticated]);

  useEffect(() => {
    async function asyncFunction() {
      if (user.user.status === UserStatus.Logged) {
        const id: string = user.user.user !== null ? user.user.user.id : "";
        const roles: string[] =
          user.user.user !== null && user.user.user.roles
            ? user.user.user.roles
            : [];
        dispatch(loadingChat(true));
        dispatch(
          initConversations(await Passerelle.getConversations(roles))
        );

        Passerelle.getConnectionChat(id).then(({ meJID, token }) => {
          dispatch(
            initChat({
              username: meJID,
              password: token
            })
          );
        });
        dispatch(loadingChat(false));
      }
    }

    // Execute the created function directly
    asyncFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user]);

  useEffect(() => {
    async function asyncFunction() {
      if (keycloak?.authenticated) {
        const newUser = {
          ...(await Lilsis.getUser(fetchWithCredentials)),
          roles: keycloak.tokenParsed!.resource_access!.orientation
            ? keycloak.tokenParsed!.resource_access!.orientation.roles
            : []
        };
        dispatch(
          setMe(
            UserStatus.Logged,
            newUser
          )
        );
      }
    }

    // Execute the created function directly
    asyncFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak?.authenticated]);

  return (
    <ApolloProvider client={Passerelle.getApolloClient(keycloak)}>
      <Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path={INTERLOCUTOR}>
                <div className="container">
                </div>
              </Route>
              <Route path="/carousel">
                <CustomCarousel interlocutors={mockInterlocutor} />
              </Route>
              <Route path="/onboarding">
                <Onboarding />
              </Route>
              <Route path={CORE}>
                <Core />
              </Route>
              <Route path={HOME}>
                <Home />
              </Route>
            </Switch>
          </Router>
        </MuiThemeProvider>
      </Fragment>
    </ApolloProvider>

    // </KeycloakProvider>
  );
};
export default App;
