import { gql } from "apollo-boost";

export const flowChatbot = gql`
  {
    getFlowPrimary {
      id
      type
      from
      content
    }
  }
`;

export const flowChatBotFromBotpress = gql`
  query($id: String!, $bot: String!) {
    getFlowPrimaryFromBotpressAndConvertToFlow(
      id: $id
      bot: $bot
      type: "text"
    ) {
      id
      type
      from
      content
    }
  }
`;

export const replaceIdsso = gql`
  mutation($idSsoUser: String!, $idUser: String!) {
    replaceUser(idSsoUser: $idSsoUser, idUser: $idUser)
  }
`;

export const getContact = gql`
  query getContact($type: String!) {
    getContact(type: $type) {
      id
      email
      isNew
      isFavorite
      firstName
      lastName
      baseline
      isCoordsVisible
      interlocutorRepresentStructure
      avatar
      conversation {
        unreadMessageCount,
        lastReadMessageId,
        messages
      }
    }
  }
`;

export const getMessages = gql`
  query getMessages($contact: String!, $limit: Float!, $messageBefore: String) {
    getMessages(contact: $contact, limit: $limit, messageBefore: $messageBefore) {
      id,
      senderId,
      date,
      content
    }
  }
`;

export const getStructureById = gql`
  query getStructureById($idStructure: String!) {
    getStructureById(id: $idStructure) {
      id,
      name,
      phone,
      logo,
      url,
      address {
        address1,
        address2,
        zipcode,
        city,
        email,
      }
    }
  }
`;
