// Core
import React, {useEffect, useRef, useState, WheelEventHandler} from "react";
import "./asset/Home.scss";
import {Box, Grid, Zoom} from "@material-ui/core";
import iluNana from "./asset/illu-nana.svg";
import ButtonCustom from "./ButtonCustom";
import ChatBot, {IStateChatbot} from "./chatBot/ChatBot";
import MessagingResponse from "../MessagingResponse/MessagingResponse";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store/redux";
import {
  changeShowButton,
  startScrollChatbot,
  stopScrollChatbot,
} from "../../store/redux/chatbot";
import {useStyles} from "./Home.styles";
import {useUserAuthentication} from "@dsk-lib/user";
import withWidth from "@material-ui/core/withWidth";
import {UserStatus} from "../../store/context/types";
import {useHistory} from "react-router-dom";
import {screenToDisplay} from "../../config/responsive";
import XsScreen from "./screen/xsScreen/XsScreen";

const Home = (props: any) => {
  const classes = useStyles();
  const {keycloak} = useUserAuthentication();
  const history = useHistory();
  const {activeScroll, showButton} = useSelector<ApplicationState,
    IStateChatbot>((state) => state.chatbot);

  const handleClick = () => {
    if (keycloak) {
      keycloak.login();
    }
  };
  const scrollChatBotRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const ref = scrollChatBotRef.current;

  const [connectionChecked, setConnectionChecked] = useState(false);
  const user = useSelector((state: ApplicationState) => state.user);

  useEffect(() => {
    if (user.user.status === UserStatus.Logged) {
      if (user.user.user?.onBoardingDone) {
        history.push("/core/messages");
      }
    }
  }, [user.user]);

  useEffect(
    () => {
      if (!screenToDisplay.includes(props.width)) {
        window.scrollTo(0, 1);
      }
    }
  )

  const changeButton = (value: boolean) => {
    dispatch(changeShowButton(value));
    setConnectionChecked(true);
  };
  const stopChatbot: WheelEventHandler<HTMLDivElement> = (e) => {
    if (
      ref &&
      ref.scrollTop === ref.scrollHeight - ref.offsetHeight &&
      !activeScroll &&
      e.deltaY > 0
    ) {
      dispatch(startScrollChatbot());
    } else if (e.deltaY < 0 && activeScroll) {
      dispatch(stopScrollChatbot());
    }
  };

  const getButton = () => {
    switch (showButton) {
      case true: {
        return (
          <ButtonCustom
            click={handleClick}
            class="homeStyle"
            text="J'ai déjà un compte"
          />
        );
      }
      case false: {
        return <MessagingResponse/>;
      }
      default: {
        return <div>PLEASE WAIT</div>;
      }
    }
  }

  const largeScreen = (
    <>
      <img src={iluNana} className="illu-nana" alt=""/>
      <div className="rectangle">
        <Box component="div" marginTop={2.125} className="grid-container-rectangle">
          <div className="generatecssdotcome_circle-up-right"/>
          <Box component="div" className="header-rectangle">
            <img className={`imgLogo ${connectionChecked && 'logoOriaAnimation'}`} src={require("./asset/logo.svg")}
                 width={140} alt=""/>
            <Box
              fontFamily="Roboto"
              fontSize={18}
              fontWeight="500"
              color="#ffffff"
              className={`${connectionChecked && 'welcomeAnimation'}`}
            >
              Bonjour, bienvenue
            </Box>
            <Zoom in={connectionChecked}>
              <Box position="relative" top={-43} left={282}>
                <ButtonCustom
                  click={handleClick}
                  class="homeStyle"
                  text="J'ai déjà un compte"
                />
              </Box>
            </Zoom>


          </Box>
          <Grid
            onWheelCapture={stopChatbot}
            ref={scrollChatBotRef}
            className="content">
            <Grid
              className={classes.column_left_row_2_item_container_xs_6}
            >
              <ChatBot
                showButton={changeButton}
                connectionChecked={connectionChecked}
              />
            </Grid>
          </Grid>
          <div className="side-right">
            <div className="generatecssdotcome_circle"/>
            <div className="generatecssdotcome_circle-up-left"/>
          </div>
          <div className="generatecssdotcome_circle-right"/>
        </Box>
        <Box component="div" className="grid-container-footer">
          <Box display="flex" justifyContent="center" alignItems="center">
            {(getButton)()}
          </Box>
        </Box>

      </div>
    </>
  );

  return (() => {
    function smScreen() {
      return (
        (
          <>
            <Grid
              onWheelCapture={stopChatbot}
              ref={scrollChatBotRef}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Box marginTop={8.5}>
                <img src={require("./asset/logo.svg")} width={120} alt=""/>
              </Box>
              <Box
                marginTop={2}
                fontFamily="Roboto"
                fontSize={18}
                fontWeight="500"
                color="#ffffff"
              >
                Bonjour, bienvenue
              </Box>
              <Box flex={1} overflow="auto">
                <ChatBot
                  showButton={changeButton}
                  connectionChecked={connectionChecked}
                />
              </Box>
            </Grid>
            <Box
              position="absolute"
              textAlign="center"
              marginLeft="auto"
              marginRight="auto"
              left={0}
              right={0}
            >
              {(getButton)()}
            </Box>
          </>
        )
      );
    }

    switch (props.width) {
      case "sm":
        return smScreen()
      case "xs":
        return <XsScreen
          changeButton={changeButton}
          connectionChecked={connectionChecked}
          getButton={getButton}
        />
      default:
        return largeScreen;
    }
  })();
};

export default withWidth()(Home);
