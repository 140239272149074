import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { combined_shape_animation } from "./asset/keyframes";
import { stylesLg } from "./styles/stylesLg";
import { stylesXl } from "./styles/stylesXl";
import { stylesSm } from "./styles/stylesSm";


export const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      'html,body': { height: '100%', margin: 0 },
      root: {
        ...stylesXl(theme).root,
        ...stylesLg(theme).root,
        [theme.breakpoints.between('xs', 530)]: {
         position: 'relative',
         height: '100vh',
        }
      },
      '::-webkit-scrollbar-track-piece': {
        backgroundColor: 'transparent'
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      button_top_right: {
        position: 'absolute',
        right: '35%',
        top: '8%',
      },
      img: {
        textAlign: 'center',
        position: 'relative',
        display: 'inline-block'
      },
      img_size: {
        position: 'absolute',
        height: '211px',
        top: '19%',
        width: '148px',
        left: '30%',
        ...stylesLg(theme).img_size
      },
      img_size_2: {
        position: 'absolute',
        left: '26%',
        width: '100px',
        height: '100px',
        top: '6%',
      },
      column_left: {
        display: 'flex',
        flexDirection: 'row-reverse'
      },
      column_left_row_2_container: {
        ...stylesSm(theme).column_left_row_2_container,
        ...stylesXl(theme).column_left_row_2_container,
        ...stylesLg(theme).column_left_row_2_container
      },
      column_left_row_2_container_2: {
        height: '71.7vh',
        alignItems: 'stretch',
        paddingTop: '13%'
      },
      column_left_row_2: {
        display: 'flex',
        flexDirection: 'row-reverse',
      },
      column_left_row_2_circle: {
        position: 'absolute',
        width: '76px',
        height: '76px',
        top: '47%',
        right: '79.5%',
        background: '#951556',
        borderRadius: '70px',
        [theme.breakpoints.between('xs', 530)]: {
          display: "none",
        }
      },
      column_left_row_2_item_container_xs_6: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,

        minHeight: ' 100%',
        height: ' 40px',
        width: '100%',
        [theme.breakpoints.between('xs', 530)]: {
          position: 'absolute',
          bottom: 0,
          top: 0,
          left: 0,
          right: 0,
          height: '100vh'
        }

      },
      column_left_circle: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        right: '78.5%',
        background: '#e9b5d2',
        borderRadius: '70px',
        marginRight: theme.spacing(9),
        [theme.breakpoints.between('xs', 530)]: {
          display: "none",
        }
      },
      img_text: {
        top: '133%',
        position: 'absolute',
        color: '#ffffff',
        letterSpacing: 'normal',
        lineHeight: 'normal',
        fontWeight: 'bold',
        fontSize: 20,
        fontFamily: 'Roboto',
        right: '42%',
        marginTop: '29px',
        ...stylesLg(theme).img_text
      },
      circle_top_right: {
        position: 'absolute',
        right: '29%',
        top: '8%',
        width: '50px',
        height: '50px',
        background: '#e9b5d2',
        borderRadius: '70px',
        // marginTop: theme.spacing(7.5)
        [theme.breakpoints.between('xs', 530)]: {
          display: "none",
        }
      },
      circle_berry: {
        width: '150px',
        height: '150px',
        borderRadius: '70px',
        background: '#951556'
      },
      circle_berry_left_container: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'flex-end',
        [theme.breakpoints.between('xs', 530)]: {
          display: "none",
        }
      },
      circle_berry_left: {
        width: '133px',
        height: '133px',
        borderRadius: '170px',
        borderColor: '#951556',
        border: '2px solid'

      },
      circle_white_right: {
        position: 'absolute',
        marginRight: '82px',
        marginTop: '75px',
        width: '106px',
        height: '106px',
        borderRadius: '170px',
        borderColor: '#ffffff',
        border: '2px solid',
        right: '5%',
        top: '9%',
        [theme.breakpoints.between('xs', 530)]: {
          display: "none",
        }

      },
      hide: {
        display: "none"
      },
      button: {
        ...stylesXl(theme).button,
        ...stylesLg(theme).button
      },
      combined_shape: {
        zIndex: -1,
        position: 'absolute',
        right: '24%',
        top: '38%',
        marginRight: '57px',
        transition: `opacity 50s ease-in;`
      },
      [`@keyframes ${combined_shape_animation}`]: {
        from: {
          opacity: 0
        },
        to: {}
      },
      '@media screen and (min-device-width: 1200px)  and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)': {
        root: {
          height: '112%',
          marginTop: 0,
          marginBottom: 0,
          padding: theme.spacing(2)
        },
        img_size: {
          position: 'absolute',
          height: '211px',
          top: '34%',
          width: '144px',
          left: '28%'
        },
        img_text: {
          top: '132%',
          color: '#ffffff',
          right: '42%',
          position: 'absolute',
          fontSize: '20px',
          marginTop: '32px',
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          lineHeight: 'normal',
          letterSpacing: 'normal'
        }
      },
    }),
);
