import {action, ActionType} from "typesafe-actions";
import {ADD_INTERLOCUTOR, REMOVE_INTERLOCUTOR} from "./type";

export const addInterlocutor = (interlocutor: string) => {
  return action(ADD_INTERLOCUTOR, interlocutor)
};

export const removeInterlocutor = (interlocutor: string) => action(REMOVE_INTERLOCUTOR, interlocutor)

const allActions = {
  addInterlocutor,
  removeInterlocutor
};

export type InterlocutorAction = ActionType<typeof allActions>
