import {KEYCLOAK_CLIENT, KEYCLOAK_REALM, KEYCLOAK_URL} from './constant'
import {KeycloakInitOptions} from "keycloak-js";

export const KEYCLOAK_CONFIG = {
  "realm": KEYCLOAK_REALM,
  "url": KEYCLOAK_URL,
  "clientId": KEYCLOAK_CLIENT,
}
export const KEYCLOAK_PROVIDER_INIT_CONFIG: KeycloakInitOptions = {
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  checkLoginIframe: false // use for keep log in on page reload
}
