import { LILSIS_URL } from "../config/constant";

const HEADERS = {
  "X-DigiSchool-Client": 'orientation'
};

export interface UserLilsis {
  id: string
  userName: string
  email: string
  emailValid: boolean
  emailBounced: boolean
  firstName: string
  lastName: string
  avatar: string
  phone: string
  birthday: Date
  gender: string
  locale: 'fr'
  address: {
    streetName: string
    city: string
    country: string
    countryCode: string
  }
  situation: {
    version: string
    domain: string
    level: string
    status: string
    year: number
  }
  newsletters: {
    digiSchool: boolean,
    partners: boolean
  }
  services: {
    [key: string]: {
      [key: string]: any
    }
  }
  roles?: string[]
}

export default class Lilsis {
  public static async getUser(
    fetchWithCredentials: (
      input?: Request | string,
      init?: RequestInit
    ) => Promise<Response>
  ): Promise<UserLilsis> {
    const user = await fetchWithCredentials(`${LILSIS_URL}/users/me`, {
      headers: HEADERS
    }).then(data => data.json());
    return user;
  }
}
