import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles({
  root: {
    float: 'left',
    margin: 28,
    width: 398

  }
});
