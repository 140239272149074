import {Theme} from "@material-ui/core";

export const stylesXl = (theme: Theme) => (
  {
    'text-oria-p': {
      '& p': {
        [theme.breakpoints.up('xl')]: {
          fontSize: '18px',
        }
      }
    }
  }
)
