// Core
// Material
import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
// Store
import { useDispatch } from "react-redux";
import { BATCH_OLD_MESSAGES, REACT_APP_ORIA_SSO_ID } from "../../../../../../../config/constant";
import { Contact } from "../../../../../../../entity/Contact";
import { Message } from "../../../../../../../entity/Message";
import { User } from "../../../../../../../entity/User";
import Passerelle from "../../../../../../../services/passerelle";
import { initMessages, loadOldMessages } from "../../../../../../../store/redux/conversation";
import MessagingResponse from "../../../../../../MessagingResponse/MessagingResponse";
import { useAppStyles } from "../coremessaging.style";
// Components
import MessageList from "./MessageList";
import MessagingHeader from "./MessagingHeader";
import NoMessages from "./NoMessage";
import withWidth from "@material-ui/core/withWidth";
import { screenToDisplay } from "../../../../../../../config/responsive";

import './messages.scss'

export interface MessagingProps {
  contact: Contact;
  user: User;
  width: string;
}

function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: any
  return (...args: Params) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}

const Messaging = (props: MessagingProps) => {
  const classes = useAppStyles();
  const dispatch = useDispatch();
  const { contact, width } = props;
  const [fetchingMessage, setFetchingMessages] = useState(false);
  const lastFetchingUnderBatch = useRef(false);
  const isScreenToDisplay = screenToDisplay.includes(width)

  const checkMessage = (messages: Message[]) => {
    if (messages.length < BATCH_OLD_MESSAGES) {
      lastFetchingUnderBatch.current = true
    }
  }
  React.useEffect(() => {
    async function fetchMessages() {
      if (contact.conversation && contact.conversation.messages.length === 0) {
        const messages = await Passerelle.getMessages(contact.id);
        checkMessage(messages)
        dispatch(initMessages(contact.id, messages));
      }
    }

    setFetchingMessages(true);
    fetchMessages();
    setFetchingMessages(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact.id]);

  const handleScrollTop = async (contact: Contact) => {
    if (contact.conversation.messages.length > 0) {
      const lastMessage = contact.conversation.messages.sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return -1;
        }
        if (a.timestamp > b.timestamp) {
          return 1;
        }
        return 0;
      })[0]
      const messages = await Passerelle.getMessages(contact.id, lastMessage.id);
      checkMessage(messages)
      dispatch(loadOldMessages(contact.id, messages));
    }
    setFetchingMessages(false);
  };

  let handle = (_c: Contact) => {
  }
  useEffect(() => {
    handle = debounce(handleScrollTop, 5000);
  }, [contact])

  const containerScroll = useRef<HTMLDivElement>(null);
  React.useEffect(() => {

    if (containerScroll && containerScroll.current) {
      containerScroll.current.addEventListener('scroll', () => {
        if (containerScroll.current?.scrollTop === 0 && !lastFetchingUnderBatch.current) {
          setFetchingMessages(true);
          handle(contact)
        }
      });
    }
  })
  return isScreenToDisplay ? (
    <Box
      display="flex"
      flexDirection="column"
      height={"100%"}
      className={classes.wrapperMessaging}
    >
      <Box>
        <MessagingHeader contact={contact} />
      </Box>
      <Box flex={1} className={classes.mainMessagingWrapper} {...{ ref: containerScroll }}>
        {fetchingMessage ? <span className={classes.loadingMessages}> <CircularProgress /></span> : null}
        {contact.conversation && contact.conversation.messages.length > 0 ? (
          <MessageList contact={contact} me={props.user} />
        ) : (
            <NoMessages />
          )}
      </Box>
      {contact.id !== REACT_APP_ORIA_SSO_ID ? (

        <Box>
          <MessagingResponse />
        </Box>
      )
        : null}
    </Box>
  ) : (<>
    <div className="messagin">
      <div className="grid-container">
        <div className="headerMessaging">
          <Box>
            <MessagingHeader contact={contact} />
          </Box>
        </div>
        <div className="content-messaging">
          <Box  {...{ ref: containerScroll }}>
            {fetchingMessage ? <span className={classes.loadingMessages}> <CircularProgress /></span> : null}
            {contact.conversation && contact.conversation.messages.length > 0 ? (
              <MessageList contact={contact} me={props.user} />
            ) : (
                <NoMessages />
              )}
          </Box>
        </div>
        {contact.id !== REACT_APP_ORIA_SSO_ID ? (
          <div className="footer-messaging">
            <Box>
              <MessagingResponse />
            </Box>
          </div>
        )
          : null}
      </div>
    </div>

  </>);
};

export default withWidth()(Messaging);
