import {createStyles, makeStyles, Theme} from "@material-ui/core";
import {stylesXl} from "./stylesXl";
import {stylesLg} from "./stylesLg";


export const chatBotStyle = makeStyles((theme: Theme) => {
    return createStyles(
      {
        'text-oria-p': {
          '& p': {
            fontFamily: 'Roboto, serif',
            fontSize: '15px',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '1.44',
            letterSpacing: '0.36px',
            color: '#ffffff',
            padding: '14px',
            display: 'inline-block',
            borderRadius: '8px',
            border: 'solid 1px rgb(33, 2, 39)',
            backgroundColor: 'rgb(33, 2, 39)',
            whiteSpace: 'pre-wrap',
            margin: '6px 6px 26px',
            ...stylesXl(theme)['text-oria-p']['& p'],
            ...stylesLg(theme)['text-oria-p']['& p']
          }
        },
        'root-modal': {
          backgroundColor: 'transparent',
        }
      }
    )
  }
)
