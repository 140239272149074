import { Select } from "antd";
import React from "react";

const { Option } = Select;

const optionFilter = (input: any, option: any) =>
  option.props.children
    .toString()
    .toLowerCase()
    .indexOf(input.toLowerCase()) >= 0;

const Onboarding = () => {
  return (
    <div>
      <Select
        style={{ width: "100%" }}
        showSearch={true}
        placeholder="Select a person"
        optionFilterProp="children"
        mode="multiple"
        filterOption={optionFilter}
      >
        <Option value="jack">Jack</Option>
        <Option value="lucy">Lucy</Option>
        <Option value="tom">Tom</Option>
      </Select>
    </div>
  );
};

export default Onboarding;
