// Global
export const APP_NAME = process.env.APP_NAME || "front-web";

// Chat
export const CHAT_URL =
  process.env.REACT_APP_CHAT_URL ||
  "wss://preprod.oria-chat.digischool.io/ws-xmpp";
export const CHAT_DOMAIN =
  process.env.REACT_APP_CHAT_DOMAIN || "preprod.oria-chat.digischool.io";
export const CHAT_SECRET =
  process.env.REACT_APP_CHAT_SECRET ||
  "0nN?AC2N[An9op?e^wGH]2O@]vt[dTrwy6XXG]vY07Ny466wiU2L;lisF^[EGnU";

// Keycloak
export const KEYCLOAK_REALM =
  process.env.REACT_APP_KEYCLOAK_REALM || "digiSchool";
export const KEYCLOAK_URL =
  process.env.REACT_APP_KEYCLOAK_URL ||
  "https://preprodaccount.digischool.io/auth";
export const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT || "oria";
export const KEYCLOAK_ONLOAD =
  process.env.REACT_APP_KEYCLOAK_ONLOAD || "check-sso";

export const url =
  process.env.REACT_APP_API_URL_FLOWCHATBOT ||
  "http://localhost:3001/mockerFlowBot";
export const URL_PASSRELLE_TO_BOTPRESS =
  process.env.REACT_APP_API_URL_PASSRELLE || "http://localhost:3001/bot";
export const URL_FOR_ASK_BOTPRESS = (idUser: string | null) =>
  `${URL_PASSRELLE_TO_BOTPRESS}/${idUser}/qualification`;

export const URL_GRAPHQL =
  process.env.REACT_APP_URL_GRAPHQL || "http://localhost:3001/graphql";
export const REACT_APP_BOT = process.env.REACT_APP_BOT || "test";

export const LILSIS_URL =
  process.env.REACT_APP_LILSIS_URL || "http://localhost:3001";

export const BATCH_OLD_MESSAGES = process.env.BATCH_OLD_MESSAGES || 20;

export const REACT_APP_ORIA_SSO_ID = process.env.REACT_APP_ORIA_SSO_ID || '';
