import { client, xml } from "@xmpp/client";
import { CHAT_URL, CHAT_DOMAIN } from "../config/constant";
import { receiveMessage } from "../store/redux/chat";
import store from "../store/configureStore";

const initConfig: Config = {
  service: CHAT_URL,
  domain: CHAT_DOMAIN,
  username: "",
  password: ""
};

export interface Config {
  service?: string;
  domain?: string;
  username?: string;
  password?: string;
}

export default class Chat {
  private static receive(message: any): void {
    store.dispatch(
      receiveMessage(
        message.getChildText("body"),
        message.attrs.from.split("@")[0]
      )
    );
  }
  private config: Config;
  private client: any;

  constructor(conf: Config) {
    this.config = { ...initConfig, ...conf };
  }

  public start(conf: Config): void {
    console.log("START XMPP");
    this.config = { ...this.config, ...conf };
    this.client = client(this.config);
    // this.client.on('error', (err: any) => {
    //   console.error('❌', err.toString())
    // })

    // this.client.on('offline', () => {
    //   console.log('⏹', 'offline')
    // })

    this.client.on("stanza", async (stanza: any) => {
      if (stanza.is("message")) {
        Chat.receive(stanza);
      }
    });

    this.client.on("online", async () => {
      // Makes itself available
      this.presence();
    });

    // // Debug
    // this.client.on('status', (status: any) => {
    //   console.debug('🛈', 'status', status)
    // })
    // this.client.on('input', (input: any) => {
    //   console.debug('⮈', input)
    // })
    // this.client.on('output', (output: any) => {
    //   console.debug('⮊', output)
    // })

    this.client.start().catch(console.error);
  }
  public send(dest: string, msg: string): void {
    const message = xml(
      "message",
      { type: "chat", to: `${dest}@${this.config.domain}` },
      xml("body", {}, msg)
    );
    // console.log("CHAT", message)
    if (this.client.status === "online") {
      this.client.send(message);
    }
  }

  private presence(): void {
    if (this.client.status === "online") {
      this.client.send(xml("presence"));
    }
  }
}
