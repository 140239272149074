import { createStore, Store } from 'redux'
// Import the state interface and our combined reducers/sagas.
import { ApplicationState, rootReducer, middleware, rootEpic, epicMiddleware, ApplicationAction } from './redux'

const w = window as any;
export function configureStore(initialState?: ApplicationState): Store<ApplicationState, ApplicationAction> {

  const createStoreWithMiddleware = middleware(createStore);

  const store = createStoreWithMiddleware(rootReducer, initialState);
  epicMiddleware.run(rootEpic);

  return store
}

export default configureStore(w.__REDUX_DEVTOOLS_EXTENSION__ && w.__REDUX_DEVTOOLS_EXTENSION__())
