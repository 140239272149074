export const RESPONSIVE = {
  /* ----------- iPad 1, 2, Mini and Air ----------- */
  IPAD_1_AND_2_MINI_AND_AIR: {
    PORTRAIT_AND_LANDSCAPE:
      "@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  and (-webkit-min-device-pixel-ratio: 1)",
    PORTRAIT:
      "@media only screen and (min-device-width: 375px)   and (min-device-width: 768px)  and (max-device-width: 1024px)   and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) ",
    LANDSCAPE:
      "@media only screen and (min-device-width: 375px)   and (min-device-width: 768px)  and (max-device-width: 1024px)   and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) "
  },
  /* ----------- iPad 3, 4 and Pro 9.7" ----------- */
  IPAD_3_AND_4_AND_PRO_9: {
    PORTRAIT_AND_LANDSCAPE:
      "@media only screen  and (min-device-width: 768px)  and (max-device-width: 1024px)  and (-webkit-min-device-pixel-ratio: 2)",
    PORTRAIT:
      "@media only screen  and (min-device-width: 768px)  and (max-device-width: 1024px) and (orientation: portrait)  and (-webkit-min-device-pixel-ratio: 2)",
    LANDSCAPE:
      "@media only screen  and (min-device-width: 768px)  and (max-device-width: 1024px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)"
  },
  /* ----------- iPhone X ----------- */
  IPHONE_X: {
    PORTRAIT_AND_LANDSCAPE:
      "@media only screen and (min-device-width: 375px)  and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3)",
    PORTRAIT:
      "@media only screen and (min-device-width: 375px)  and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)",
    LANDSCAPE:
      "@media only screen and (min-device-width: 375px)  and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)"
  },

  IPHONE_6_AND_6S_AND_7_AND_8: {
    PORTRAIT_AND_LANDSCAPE:
      "@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2)",
    PORTRAIT:
      "@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)",
    LANDSCAPE:
      "@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2)  and (orientation: landscape)"
  },

  IPHONE_6_PLUS_AND_7_PLUS_AND_8_PLUS: {
    PORTRAIT_AND_LANDSCAPE:
      "@media only screen and (min-device-width: 414px)  and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3)",
    PORTRAIT:
      "@media only screen  and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)",
    LANDSCAPE:
      "@media only screen  and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)"
  },
  IPHONE_4_AND_4S: {
    PORTRAIT_AND_LANDSCAPE:
      "@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2)",
    PORTRAIT:
      "@media only screen and (min-device-width: 320px)  and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)",
    LANDSCAPE:
      "@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)"
  },
  IPHONE_5_AND_5S_AND_5C_AND_5SE: {
    PORTRAIT_AND_LANDSCAPE:
      "@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2)",
    PORTRAIT:
      "@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)",
    LANDSCAPE:
      "@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)"
  }
};

export const screenToDisplay: any = ['lg', 'md', 'xl']
