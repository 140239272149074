export interface InterlocutorType {
  id?: any;
  title: string;
  address: string | undefined;
  partner: boolean;
  zip: string;
  city: string;
  listChip: string[];
}

export interface CardPropsInterlocutor {
  interlocutor: InterlocutorType
}

export interface PropsInterlocutor {
  interlocutors: InterlocutorType[]
  handleClosDialogCarousel: ()=>void
}

export const mockInterlocutor: InterlocutorType[] = [
  {
    id: 1,
    title: `ENSPIMA : École Nationale Supérieure pour la Performance Industrielle et la Maintenance Aéronautique`,
    address: undefined,
    zip: '06250',
    partner: true,
    city: 'Mougins',
    listChip: [
      'BTS MCO',
      ' Marseille',
      'Alternance',
      'Ecole Public'
    ]
  }
  , {
    id: 2,
    title: 'IDRAC Nice 3 ',
    address: '1200 avenue Maurice Donat',
    zip: '06250',
    partner: false,
    city: 'Mougins',
    listChip: [
      'BTS MCO',
      ' Marseille',
    ]
  }
  , {
    id: 3,
    title: 'IDRAC Nice 2 ',
    address: '1200 avenue Maurice Donat',
    zip: '06250',
    partner: true,
    city: 'Mougins',
    listChip: [
      'BTS MCO',
      ' Marseille',
      'Alternance',
      'Ecole Public'
    ]
  }
  , {
    id: 4,
    title: 'IDRAC Nice 4',
    address: '1200 avenue Maurice Donat',
    zip: '06250',
    partner: false,
    city: 'Mougins',
    listChip: [
      'BTS MCO',
      ' Marseille',
      'Alternance',
      'Ecole Public',
      'Initiale'
    ]
  }
]
