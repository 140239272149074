import { Reducer } from "redux";
import { UserInterface, UserStatus } from "../../context/types";
import { SET_ME, SET_USER_ID, UserAction } from "./actions";

export interface UserState {
  user: UserInterface;
  idUser: string | null;
  errors: any;
  loading: boolean;
}

const initialState: UserState = {
  user: { status: UserStatus.Anonymous, user: null },
  idUser: null,
  errors: undefined,
  loading: false
};

const reducer: Reducer<UserState, UserAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_ME:
      let onBoardingDone = false;
      if (action.payload.user['services']) {
        if (action.payload.user['services']['orientation']) {
          if (action.payload.user['services']['orientation']['onBoardingDone']) {
            onBoardingDone = true;
          }
        }
      }
      return {
        ...state, user: {
          status: action.payload.status, user: {
            id: action.payload.user.id,
            firstName: action.payload.user.firstName,
            lastName: action.payload.user.lastName,
            email: action.payload.user.email,
            phone: action.payload.user.phone,
            avatar: action.payload.user.avatar,
            roles: action.payload.user.roles,
            onBoardingDone,
          }
        }
      }
    case SET_USER_ID:
      return {
        ...state, idUser: action.payload
      }
    default:
      return state;
  }
};

export { reducer as userReducer };
