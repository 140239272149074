export const INIT_STATE = 'INIT_STATE'
export const ADD_MESSAGES = 'ADD_MESSAGES'
export const INIT_MESSAGE_FROM_BOTPRESS_SUCSESS = 'INIT_MESSAGE_FROM_BOTPRESS_SUCSESS'

export const ADD_MESSAGE_IN_MESSAGE_ASYNC = 'ADD_MESSAGE_IN_MESSAGE_ASYNC'
export const ADD_MESSAGES_ASYNC = 'ADD_MESSAGES_ASYNC'

export const CHANGE_BUTTON_SHOW_BUTTTON = 'CHANGE_BUTTON_SHOW_BUTTTON'
export const START_CHATBOT_FLOW = 'START_CHATBOT_FLOW'


export const STOP_CHATBOT_FLOW  = 'STOP_CHATBOT_FLOW'

export const START_SCROLL_TCHAT = 'START_SCROLL_TCHAT'
export const STOP_SCROLL_TCHAT = 'STOP_SCROLL_TCHAT'


export const START_PENDING = 'START_PENDING'
export const STOP_PENDING = 'STOP_PENDING'


