import { makeStyles, createStyles } from '@material-ui/core'

export const useAppStyles = makeStyles(() => createStyles({
  main: {
    color: `#35013f`,
    padding: "20px",
    fontFamily: 'Bitter',
    fontSize: 30,
    fontWeight: 'bold'
  },
  small: {
    width: '140px',
    height: '19px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#000000',
    display: 'block'
  }
}
))
