import { Epic } from "redux-observable";
import { empty, from, of } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { ApplicationState } from "../";
import Chat from "../../../services/chat";
import Passerelle from "../../../services/passerelle";
import { ConversationAction, initConversations } from "../conversation";
import {
  addMessageReceived,
  addMessageSent,
  ChatAction,
  INIT_CHAT,
  RECEIVE_MESSAGE,
  SEND_MESSAGE
} from "./actions";

let chat: Chat | null = null;

export const initChatEpic: Epic<ChatAction> = action$ =>
  action$.pipe(
    filter(isOfType(INIT_CHAT)),
    mergeMap(({ payload }) => {
      if (chat === null) {
        chat = new Chat({});
        chat.start(payload);
      }
      return empty();
    })
  );

export const sendMessageEpic: Epic<ChatAction, ChatAction, ApplicationState> = (
  action$,
  store
) =>
  action$.pipe(
    filter(isOfType(SEND_MESSAGE)),
    mergeMap(({ payload }) => {
      if (chat !== null) {
        chat.send(payload.destinataire, payload.message);
        const { user } = store.value.user;
        return of(
          addMessageSent(
            payload.message,
            user.user ? user.user.id : "",
            new Date().getTime(),
            payload.destinataire
          )
        );
      }
      return empty();
    })
  );

export const receiveMessageEpic: Epic<
  ChatAction | ConversationAction,
  ChatAction | ConversationAction,
  ApplicationState
> = (action$, store) =>
    action$.pipe(
      filter(isOfType(RECEIVE_MESSAGE)),
      mergeMap(({ payload }) => {
        const addMessageReceivedAction = addMessageReceived(
          payload.body,
          payload.idUser,
          new Date().getTime()
        );
        const contacts = store.value.conversation.contacts;

        const id = store.value.user.user?.user?.id;
        if (
          contacts.find(c => c.id === payload.idUser) === undefined &&
          id !== undefined
        ) {
          const roles = store.value.user.user?.user?.roles ?? [];
          return from(
            Passerelle.getConversations(roles).then(initConversations)
          ).pipe(
            mergeMap(action => {
              return of(action, addMessageReceivedAction);
            })
          );
        } else {
          return of(addMessageReceivedAction);
        }
      })
    );
