// MOCKS
// import { contacts } from '../Mocks/Contacts'
// import { Messages } from '../Mocks/Messages'
import Axios, { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";
import { BATCH_OLD_MESSAGES, CHAT_DOMAIN, CHAT_SECRET, url, URL_GRAPHQL } from "../config/constant";
import { Contact } from "../entity/Contact";
import { Message } from "../entity/Message";
import { MessageObject } from "../views/Home/chatBot/ChatBot";
import { DataFromBotpress } from "../views/Home/chatBot/interfaces";
import { getContact, getMessages, getStructureById, replaceIdsso } from "./graphQlQuery";
import ApolloClient from "apollo-boost";

interface IConnection {
  meJID: string;
  token: string;
}

export default class Passerelle {
  // TODO don't expose this
  public static apolloClient: ApolloClient<any>;

  public static getApolloClient(keycloak: any): ApolloClient<any> {
    const apolloClient = new ApolloClient({
      uri: `${URL_GRAPHQL}`,
      request: (operation) => {
        const token = keycloak.token
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : ''
          }
        })
      }
    });
    this.apolloClient = apolloClient;
    return this.apolloClient
  }

  public static async getConversations(
    roles: string[]
  ): Promise<Contact[]> {
    const cont = await this.apolloClient.query({
      query: getContact,
      variables: {
        type: roles.indexOf("intervenant") > -1 ? "interlocutor" : "user"
      },
      fetchPolicy: "no-cache"
    });

    return cont.data.getContact.map((c: Contact) => {
      c.createdAt = new Date();
      return c;
    });
  }

  public static async getMessageFromBotPress(): Promise<MessageObject[]> {
    const response: AxiosResponse = await Axios.get<
      DataFromBotpress,
      AxiosResponse
    >(url);
    return response.data;
  }

  public static async getMessages(contact: string, messageBefore?: string): Promise<Message[]> {
    const variables: any = {
      contact,
      limit: BATCH_OLD_MESSAGES
    }
    if (messageBefore) {
      variables.messageBefore = messageBefore
    }
    const messages = await this.apolloClient.query({
      query: getMessages,
      variables,
      fetchPolicy: "no-cache"
    });
    return messages.data.getMessages.map((m: any) => {
      return {
        id: m.id,
        senderId: m.senderId,
        timestamp: new Date(m.date).getTime(),
        content: m.content
      }
    });
  }

  public static async getConnectionChat(id: string): Promise<IConnection> {
    const meJID: string = `${id}@${CHAT_DOMAIN}`;
    const token: string = jwt.sign({ jid: id }, CHAT_SECRET, {
      expiresIn: "1000h"
    });

    return { meJID, token };
  }

  public static async setSsoId(
    userSso: string,
    idUserFromLocalStorage: string
  ) {
    return await this.apolloClient.mutate({
      mutation: replaceIdsso,
      variables: {
        idSsoUser: userSso,
        idUser: idUserFromLocalStorage
      }
    });
  }

  public static async getStructureById(
    idStructure: string,
  ) {
    return await this.apolloClient.query({
      query: getStructureById,
      fetchPolicy: "cache-first",
      variables: {
        idStructure
      }
    });
  }
}
