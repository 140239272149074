// Core
import React from "react";
import { Avatar, Box, Button, Grid } from "@material-ui/core";

import { Contact } from "../../../../../../entity/Contact";

import { useAppStyles } from "../../profile.style";

import { ProfileItem } from "./components/ProfileItem";
import { displayName } from "../../../../../../services/helper";

interface ProfileProps {
  user: Contact;
  editable: boolean;
}
const Profile = (props: ProfileProps) => {
  const classes = useAppStyles();
  const { user, editable } = props;
  return (
    <>
      <header className={classes.header} />
      <Box display="flex" flexDirection="row" className={classes.coordsWrapper}>
        <Box flex={2} className={classes.avatarWrapper}>
          <Avatar src={user.avatar} className={classes.avatar} />
        </Box>
        <Box flex={8} className={classes.coords}>
          <h3 className={classes.name}>
            {displayName(user.firstName, user.lastName)}
          </h3>
          <p>{user.email}</p>
          <p>{user.phone}</p>
          <p>
            <input type="checkbox" id="private-coords" />
            <label htmlFor="private-coords">
              Je souhaite que mes coordonnées restent privées.
            </label>
          </p>
          <p className={classes.action}>
            <Button variant="outlined">
              {editable ? "Modifier mon profil" : "Contacter"}
            </Button>
          </p>
        </Box>
      </Box>
      {user.profil ? (
        <div>
          <Grid container={true} className={classes.profileWrapper}>
            <Grid container={true} item={true} xs={12} sm={3}>
              <Grid item={true} xs={6} sm={12}>
                <ProfileItem
                  icon="language"
                  title="Statut"
                  value={user.profil.status}
                />
                <ProfileItem
                  icon="language"
                  title="Niveau d'étude"
                  value={user.profil.studylevel}
                />
                <ProfileItem
                  icon="language"
                  title="Domaine d'étude"
                  value={user.profil.studydomain}
                />
                <ProfileItem
                  icon="language"
                  title={user.profil.etablishmenttype}
                  value={user.profil.etablishment.name}
                />
              </Grid>
              <Grid item={true} xs={6} sm={12}>
                <ProfileItem
                  icon="language"
                  title="secteur d'activité"
                  value={user.profil.sector.map(i => i.name).join("\n")}
                />
                <ProfileItem
                  icon="language"
                  title="Type de formation"
                  value={user.profil.typeformation.map(i => i.name).join("\n")}
                />
                <ProfileItem
                  icon="language"
                  title="Intéressé par les métiers"
                  value={user.profil.interest.profession
                    .map(i => i.name)
                    .join("\n")}
                />
                <ProfileItem
                  icon="language"
                  title="Intéressé par les écoles"
                  value={user.profil.interest.school
                    .map(i => i.name)
                    .join("\n")}
                />
                <ProfileItem
                  icon="language"
                  title="Intéressé par les diplomes"
                  value={user.profil.interest.diploma
                    .map(i => i.name)
                    .join("\n")}
                />
              </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={7}>
              <h5 className={classes.itemTitle}>Je parle un peu de moi :</h5>
              <p>
                {user.profil.description.length > 0
                  ? user.profil.description
                  : `Nous avons aucune description te concernant`}
              </p>
            </Grid>
          </Grid>
        </div>
      ) : (
          ""
        )}
    </>
  );
};

export default Profile;
