// Core
import React from 'react'

// Styles
import { useAppStyles } from './itemheader.style'

export interface ItemHeaderProps {
  text: string;
  baseline?: string;
}

const ItemHeader = (props: ItemHeaderProps) => {
  const classes = useAppStyles()

  return (
    <>
      <h3 className={classes.main}>
        {props.text}
        {props.baseline ? <small className={classes.small}>{props.baseline}</small> : ''}</h3>

    </>
  )
}

export default ItemHeader
