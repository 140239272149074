import makeStyles from "@material-ui/core/styles/makeStyles";

export const cardInterlocutorStyle = makeStyles({
  root: {
    width: "100%",
    "&:hover": {
      transform: 'translateX(1%)',
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
    },
  },
  rootChip: {
    margin: 2
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  avatar: {
    backgroundColor: '#951556',
  },
  title: {
    fontSize: 14,
    display: "inline-block"
  },
  pos: {
    marginBottom: 12
  },
  textChip: {
    '&::parent': {
      margin: 2
    },
    color: '#951556'
  },
  photoContact: {
    display: "inline",
    paddingRight: "34px",
    paddingLeft: "24px"
  },
  titleSchool: {
    fontFamily: "Bitter",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.34px",
    color: "#951556"
  },
  contentSchool: {
    display: 'flex',
    "& p": {
      display: "inline-block",
      paddingBottom: "19px"
    },
    "& img": {
      display: "inline-block",
      marginTop: "-25px"
    }
  },
  bottom: {
    textAlign: "center"
  },
  MuiCardActions: {
    "& -root": {
      textAlign: "center"
    }
  },
  select: {
    display: "inline-block"
  },
  contentContainerTextSchool: {
    marginRight: "-16px",
    paddingRight: "2px",
    textAlign: "center",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    backgroundColor: "#951556"
  },
  contentTextSchool: {
    // float: 'right',
    color: "#fff",
    // paddingRight: '4px',
    //paddingTop: "1px"
  },
  contentSchoolPlaces: {
    display: "inline-block",
    paddingLeft: "14px"
  },
  img: {
    display: "inherit !important"
  },
  diploma: {
    // borderRadius: '20px',
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "baseline",
    flexWrap: "wrap"
    // justifyContent: 'space-around'
    // '& p': {
    //   opacity: 0.3,
    //   display: 'inline-block',
    //   padding: '1px',
    //   fontSize: '14px',
    //   fontWeight: 'normal',
    //   fontStretch: 'normal',
    //   fontStyle: 'normal',
    //   lineHeight: 1.29,
    //   letterSpacing: 'normal',
    //   textAlign: 'center',
    //   color: 'rgb(149, 20, 86)',
    //   backgroundColor: '#951556',
    // }
  },
  button: {
    // backgroundColor: "#951556"
  }
});

