import {Theme} from "@material-ui/core";

export const stylesXl = (theme: Theme) => (
  {
    container_carousel_interlocutor : {
      [theme.breakpoints.up('xl')]: {
 /*       width: "1012px",
        height: "411px"*/
      }
    }
  }
)
