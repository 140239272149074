import { Reducer } from "redux";
import { Contact } from "../../../entity/Contact";
import {
  ADD_MESSAGE_RECEIVE,
  ADD_MESSAGE_SEND,
  ConversationAction,
  INIT_CONVERSATIONS,
  INIT_MESSAGES,
  LOADING_CHAT,
  LOAD_OLD_MESSAGES,
  RESET_LAST_MESSAGE,
  SELECT_CONVERSATION
} from "./actions";

export interface ConversationState {
  currentContact: string | null;
  contacts: Contact[];
  errors: any;
  loading: boolean;
}

const initialState: ConversationState = {
  currentContact: null,
  contacts: [],
  errors: undefined,
  loading: false
};

const reducer: Reducer<ConversationState, ConversationAction> = (
  state = initialState,
  action
) => {
  let contacts: Contact[] | null = null;
  switch (action.type) {
    case SELECT_CONVERSATION:
      return { ...state, currentContact: action.payload };
    case INIT_CONVERSATIONS:
      return { ...state, contacts: action.payload };
    case LOADING_CHAT:
      return { ...state, loading: action.payload };
    case INIT_MESSAGES:
      contacts = state.contacts.map(c => {
        if (c.id === action.payload.id) {
          c.conversation.messages = action.payload.messages;
        }
        return c;
      });
      return { ...state, contacts };
    case LOAD_OLD_MESSAGES:
      contacts = state.contacts.map(c => {
        if (c.id === action.payload.id) {
          c.conversation.messages = [...action.payload.messages, ...c.conversation.messages];
        }
        return c;
      });
      return { ...state, contacts };
    case ADD_MESSAGE_RECEIVE:
      contacts = state.contacts.map(c => {
        if (c.id === action.payload.senderId) {
          c.isNew = false;
          c.conversation.messages.push(action.payload);
          if (c.id !== state.currentContact) {
            c.conversation.unreadMessageCount =
              c.conversation.unreadMessageCount + 1;
          }
        }
        return c;
      });
      return { ...state, contacts };
    case ADD_MESSAGE_SEND:
      contacts = state.contacts.map(c => {
        if (c.id === action.payload.destinataire) {
          c.isNew = false;
          c.conversation.messages.push(action.payload.message);
        }
        return c;
      });
      return { ...state, contacts };
    case RESET_LAST_MESSAGE:
      contacts = state.contacts.map(c => {
        if (c.id === action.payload) {
          if (c.conversation) {
            c.conversation.unreadMessageCount = 0;
          }
          if (c.conversation && c.conversation.messages.length > 0) {
            c.conversation.lastReadMessageId =
              c.conversation.messages[c.conversation.messages.length - 1].id;
          }
        }
        return c;
      });
      return { ...state, contacts };
    default:
      return state;
  }
};

export { reducer as conversationReducer };
