import {Theme} from "@material-ui/core";

export const stylesLg = (theme: Theme) => (
  {
    root: {
      [theme.breakpoints.down('lg')]: {
        // height: '112%',
        // marginTop: 0,
        // marginBottom: 0,
        // padding: theme.spacing(2)
      }
    },
    img_size: {
      [theme.breakpoints.down('lg')]: {
        position: 'absolute',
        height: '191px',
        top: '34%',
        width: '122px',
        left: '28%'
      }

    },
    button: {
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        marginTop: '50px',
        width: '50%',
      }
    },
    img_text: {
      [theme.breakpoints.down('lg')]: {
        top: '132%',
        color: '#ffffff',
        right: '42%',
        position: 'absolute',
        fontSize: '20px',
        marginTop: '32px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        lineHeight: 'normal',
        letterSpacing: 'normal'
      },
    },
    column_left_row_2_container: {
      [theme.breakpoints.up('lg')]: {
        height: '56vh',
        alignItems: 'stretch',
        paddingTop: '8%',
      }
    }
  }
)
