import React, { useState } from "react";
import { InterlocutorType } from "../type";

import "./customCarousel.scss";
import { CardContent, Card } from "@material-ui/core";
import { useStyles } from "./customCarousel.style";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import places from "../assets/ic-lieu.svg";
import { DiplomaComponentStyle } from "../CardInterlocutor/CardInterlocutor";

const CustomCarousel = (props: any) => {
  const [validateCardStatus] = useState(true);
  const handleClick = () => {
    console.log("click");
  };
  const classes: any = useStyles();
  const { interlocutors } = props;
  return (
    <div className="container-carousel">
      {interlocutors.map((interlocutor: InterlocutorType) => (
        <Card className={classes.root}>
          {
            <CardContent>
              <div>
                <div className={classes.photoContact}>1</div>
                <div className={classes.title}>
                  <span>ÉCOLE</span>
                  <p className={classes.titleSchool}>{interlocutor.title}</p>
                </div>
                <div
                  className={classes.select}
                  style={{ float: "right" }}
                  onClick={handleClick}
                >
                  {validateCardStatus ? (
                    <CheckCircleIcon htmlColor="#951556" />
                  ) : (
                    <CheckCircleOutlineIcon htmlColor="#e9b5d2" />
                  )}
                </div>
              </div>
              <div className={classes.contentSchool}>
                <img src={places} alt="" />
                <div className={classes.contentSchoolPlaces}>
                  <p>
                    {interlocutor.address}
                    <br />
                    {interlocutor.zip} {interlocutor.city}
                  </p>
                </div>
                {interlocutor.partner && (
                  <div className={classes.contentContainerTextSchool}>
                    <p className={classes.contentTextSchool}>Partnaire</p>
                  </div>
                )}
              </div>
              <div className={classes.diploma}>
                {interlocutor.listChip.map(element => (
                  <DiplomaComponentStyle key={`compenent_${element}`}>
                    {element}
                  </DiplomaComponentStyle>
                ))}
              </div>
            </CardContent>
          }
        </Card>
      ))}
    </div>
  );
};

export default CustomCarousel;
