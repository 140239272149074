import { action, ActionType } from "typesafe-actions";
import { UserLilsis } from "../../../services/lilsis";
import { UserStatus } from "../../context/types";

export const SET_ME = "SET_ME";
export const SET_USER_ID = "SET_USER_ID";

export const setMe = (status: UserStatus, user: UserLilsis) => action(SET_ME, { status, user });
export const setUserId = (idUser: any) => action(SET_USER_ID, idUser)

const allActions = { setMe, setUserId };
export type UserAction = ActionType<typeof allActions>;
