import styled from "styled-components";

const PCustomQuicklyReplies = styled.p`
      min-width: 88.56px;
      font-family: Roboto, serif;
      font-size: 14px;
      //font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: 0.36px;
      color: #ffffff;
      padding: 14px;
      display: inline-block;
      border-radius: 38px;
      border: solid 2px #ffffff;
      background-color: transparent;
      white-space: pre-wrap;
      margin: 6px 6px 26px;
      text-align: center;
      &:hover {
            color: black;
            background-color: white;
      }
`

export {PCustomQuicklyReplies}
