import { Epic } from 'redux-observable';
import { merge, of } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { ConversationState } from "./";
import { CHANGE_CONVERSATION, ConversationAction, resetLastMessageConversation, selectConversation } from "./actions";

export const changeConversationEpic: Epic<
  ConversationAction,
  ConversationAction,
  ConversationState
> = (action$) =>
  action$.pipe(
    filter(isOfType(CHANGE_CONVERSATION)),
    mergeMap(({ payload }) => {
      return merge(
        of(selectConversation(payload)),
        of(resetLastMessageConversation(payload))
      );
    })
  );
