// Core
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Components
import ResponseText from './ResponseText'
import { useAppStyles } from './messagingresponse.style'

import { sendMessage } from '../../store/redux/chat'
import { ApplicationState } from '../../store/redux'
import { MessageObject } from "../Home/chatBot/ChatBot";
import { addMessageInMessageAsync, startScrollChatbot } from "../../store/redux/chatbot";
import { setUserId } from "../../store/redux/user";
import { createUuid, sendMessageToBotPress } from "../../common/common";
import { useUserAuthentication } from "@dsk-lib/user";
import {Grow} from "@material-ui/core";


export const isStudent = 'student';

const MessagingResponse = () => {
  const { idUser } = useSelector((state: ApplicationState) => state.user);
  const dispatch = useDispatch();
  const { keycloak } = useUserAuthentication();
  useEffect(() => {
    if (!idUser) {
      dispatch(setUserId(createUuid()))
    }
  }, []);
  const classes = useAppStyles();
  const { currentContact } = useSelector((state: ApplicationState) => state.conversation);
  const { activeScroll } = useSelector((state: ApplicationState) => state.chatbot);

  const handleSubmit = (msg: string) => {
    const message: MessageObject = {
      type: 'text',
      from: isStudent,
      content: [msg]
    };

    if (currentContact !== null) {
      dispatch(
        sendMessage(currentContact, msg),
      )
    } else {
      dispatch(addMessageInMessageAsync(message));
      sendMessageToBotPress(message, idUser, dispatch, keycloak);
    }
    if (!activeScroll) {
      dispatch(startScrollChatbot())
    }

    if (message.from === isStudent) {
      console.log("My message is", msg)
    }
  };

  return (
    <div className={classes.responseWrapper}>
      <Grow
        in={true}
        style={{transformOrigin: '0 0 0'}}
        {...({timeout: 1000})}
      >
      <ResponseText submit={handleSubmit} />
      </Grow>
    </div>
  )

};

export default MessagingResponse
