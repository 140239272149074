// Core
import React from "react";
import { useParams } from "react-router-dom";

import { userContextWrapper } from "../../../../store/context/UserContext";
// import { useAppStyles } from './app.styles'

import { Contact } from "../../../../entity/Contact";
import Profile from "./components/Profile/Profile";
import { ApplicationState } from "../../../../store/redux";
import { useSelector } from "react-redux";

interface CoreProfileProps {
  user: Contact;
}

const CoreProfilePage = (props: CoreProfileProps) => {
  // const classes = useAppStyles()
  const { user } = props;
  const { id } = useParams();
  const { contacts } = useSelector(
    (state: ApplicationState) => state.conversation
  );
  const [currentContact, setCurrentContact] = React.useState<Contact | null>(
    null
  );
  const [editable, setEditable] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (user && id === user.id) {
      setCurrentContact(user);
      setEditable(true);
    } else {
      const contactsFiltered: Contact | undefined = contacts.find(
        c => c.id === id
      );
      if (contactsFiltered) {
        setCurrentContact(contactsFiltered);
        setEditable(false);
      }
    }
  }, [id, setCurrentContact, contacts, setEditable, user]);

  return (
    <>
      {currentContact ? (
        <Profile user={currentContact} editable={editable} />
      ) : (
        ""
      )}
    </>
  );
};

export default userContextWrapper(CoreProfilePage);
