// Core
import React, { useState } from "react";
import { Link } from "react-router-dom";

// Material
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Chip, Checkbox, Box
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
// Styles
import { useAppStyles, StyledBadge } from "./contactitem.style";

import { Contact } from "../../../../../../../../entity/Contact";

import {
  CORE_MESSAGE_USER,
  getUrl
} from "../../../../../../../../config/routing";
import clsx from "clsx";
import { formatDistanceToNow, differenceInHours, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { displayName } from "../../../../../../../../services/helper";

const formatDistanceLocale: any = {
  lessThanXMinutes: {
    one: '1m',
    other: '{{count}}m'
  },
  xMinutes: {
    one: '1m',
    other: '{{count}}m'
  },
  aboutXHours: {
    one: '1h',
    other: '{{count}}h'
  },
  xHours: {
    one: '1h',
    other: '{{count}}h'
  }, // Difference: Add time to the date
};

const locale = {
  ...fr,
  formatDistance: (token: string | number, count: number, options: any) => {
    options = options || {};
    var result;

    if (typeof formatDistanceLocale[token] === 'string') {
      result = formatDistanceLocale[token];
    } else if (count === 1) {
      result = formatDistanceLocale[token].one;
    } else {
      result = formatDistanceLocale[token].other.replace('{{count}}', count);
    }

    if (options.addSuffix) {
      if (options.comparison > 0) {
        return 'dans ' + result;
      } else {
        return 'il y a ' + result;
      }
    }

    return result;
  },
};

export interface ContactItemProps {
  contact: Contact;
  isExtended?: boolean;
  isCurrent?: boolean;
  isBot: boolean;
  handleClickContact?: () => void;
  handleCheckboxChange?: () => void;
}
const formatDate = (date: Date): string => {
  return differenceInHours(new Date(), date) >= 24 ? format(date, "dd/MM/yyyy") : formatDistanceToNow(date, { locale: locale, addSuffix: true });
}

const ContactItem = (props: ContactItemProps) => {
  const classes = useAppStyles();
  const [checked, setChecked] = useState<boolean>(false);
  const { contact, handleClickContact, handleCheckboxChange, isBot } = props;

  const handleChecked = () => {
    setChecked(!checked);
    if (handleCheckboxChange) {
      handleCheckboxChange()
    }
  }
  // const handleShowStructureOrProfile = (e: any, contact: Contact) => {
  //   console.log('CONTACT', e, contact)
  // }

  const tab = [...contact?.conversation?.messages];
  const date = tab && tab.length > 0 ? formatDate(new Date(tab[tab.length - 1].timestamp)) : formatDate(contact.createdAt);
  const baseline = !isBot ? contact.baseline : 'Ton bot préféré';

  return (
    <Link
      to={{
        pathname: getUrl(CORE_MESSAGE_USER, {
          id: contact.id
        })
      }}
      className={clsx(classes.list, {
        [classes.currentConversation]: props.isCurrent,
        [classes.unreadOrNewConversation]: contact.conversation.unreadMessageCount > 0 || contact.isNew,
      })}
    >
      <ListItem
        onClick={handleClickContact}
      >

        <ListItemIcon>
          <>
            {!isBot ? (<Checkbox checked={checked} onChange={handleChecked} className={clsx(classes.checkbox, {
              [classes.checkboxCurrent]: props.isCurrent,
            })}></Checkbox>) : null}
            <Box className={clsx(classes.preIconWrapper, {
              [classes.hidden]: !props.isExtended,
            })}>
              {
                !isBot && contact.conversation &&
                  contact.conversation.unreadMessageCount > 0 ? (
                    <Chip
                      size="small"
                      label={`+${contact.conversation.unreadMessageCount}`}
                      className={classes.unread}
                    />
                  ) : contact.isNew ? (
                    <Chip
                      size="small"
                      label={"NEW"}
                      className={classes.newContact}
                    />
                  ) : null}
            </Box>
            <StyledBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              variant="dot"
            >
              <Avatar
                src={contact.avatar}
                alt={displayName(contact.firstName, contact.lastName)}
                className={classes.img}
                classes={{
                  root: classes.imgRoot
                }}
              />
            </StyledBadge>
          </>
        </ListItemIcon>
        <ListItemText
          primary={displayName(contact.firstName, contact.lastName)}
          classes={{
            root: classes.itemRoot,
            primary: classes.itemPrimary,
            secondary: props.isCurrent
              ? classes.itemSecondaryCurrent
              : classes.itemSecondary
          }}
          secondary={baseline}
          title={baseline}
        />
        <ListItemSecondaryAction
          classes={
            {
              root: classes.secondaryActionRoot
            }
          }
        >
          {/* {!isBot && (contact.isCoordsVisible || contact.interlocutorRepresentStructure) ? <Box component="span" onClick={(e) => handleShowStructureOrProfile(e, contact)} className={clsx(classes.btnProfile, {
            [classes.hidden]: !props.isExtended,
            [classes.btnProfileCurrent]: props.isCurrent,
          })}>{contact.interlocutorRepresentStructure ? 'Voir la structure' : 'Voir le profil'} </Box> : <></>} */}
          <Box component="span" className={clsx(classes.create, { [classes.createCurrent]: props.isCurrent, })}>{date}</Box>
          <Box className={clsx(classes.preIconWrapper, {
            [classes.hidden]: props.isExtended,
          })}>

            {
              !isBot && contact.conversation &&
                contact.conversation.unreadMessageCount > 0 ? (
                  <Chip
                    size="small"
                    label={`+${contact.conversation.unreadMessageCount}`}
                    className={classes.unread}
                  />
                ) : contact.isNew ? (
                  <Chip
                    size="small"
                    label={"NEW"}
                    className={classes.newContact}
                  />
                ) : null}
          </Box>
        </ListItemSecondaryAction>
      </ListItem>
      {/* {!isBot && (contact.isCoordsVisible || contact.interlocutorRepresentStructure) ? <Box component="span" onClick={(e) => handleShowStructureOrProfile(e, contact)} className={clsx(classes.btnProfile, {
        [classes.hidden]: props.isExtended,
        [classes.btnProfileCurrent]: props.isCurrent,
      })}>{contact.interlocutorRepresentStructure ? 'Voir la structure' : 'Voir le profil'} </Box> : <></>} */}
    </Link>
  );
};

export default ContactItem;
