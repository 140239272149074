import React from 'react'
import { UserInterface } from './types'

export const { Consumer, Provider } = React.createContext({})

export interface UserContext {
  userInterface: UserInterface
  info?: any
}

export function userContextWrapper<P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FunctionComponent<P & UserContext & any> {
  return (props: P & UserContext): React.ReactElement<HTMLElement> => (
    <Consumer>{(value: any): React.ReactElement<HTMLElement> => <WrappedComponent {...props} {...value} />}</Consumer >
  )
}
