import { Reducer } from "redux";
import {
  ADD_INTERLOCUTOR,
  InterlocutorState,
  REMOVE_INTERLOCUTOR
} from "./type";
import { InterlocutorAction } from "./actions";

const initialState: InterlocutorState = {
  interlocutorSelected: []
};

const reducer: Reducer<InterlocutorState, InterlocutorAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ADD_INTERLOCUTOR:
      return {
        ...state,
        interlocutorSelected: [...state.interlocutorSelected, action.payload]
      };
    case REMOVE_INTERLOCUTOR:
      return {
        ...state,
        interlocutorSelected: state.interlocutorSelected.filter(
          interlocutor => interlocutor !== action.payload
        )
      };
    default:
      return state;
  }
};

export { reducer as interlocutorReducer };
