import styled, {keyframes} from "styled-components";
import {fadeInRight} from "react-animations";
import {config} from "./config";

const fadeInRightAnimation = keyframes`${fadeInRight}`;
const DivOnBoardingOria = styled.div`
  animation: ${config.durationSecondAnimation}s  ${fadeInRightAnimation};
`;

export {DivOnBoardingOria}
