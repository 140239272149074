import { makeStyles, createStyles } from '@material-ui/core'

const imgHeader = "https://images.pexels.com/photos/697662/pexels-photo-697662.jpeg?auto=compress&cs=tinysrgb"

export const useAppStyles = makeStyles(() => createStyles({
  action: {
    position: `absolute`,
    top: `0px`,
    right: `0px`,
    '@media screen and (max-width: 768px) ': {
      position: "relative",
      width: '100%',
      textAlign: 'center'
    }
  },
  coordsWrapper: {
    padding: "0 60px",
    '@media screen and (max-width: 768px) ': {
      display: `block`,
      padding: "0 10px",
      textAlign: "center"
    }
  },
  coords: {
    position: 'relative'
  },
  avatarWrapper: {
    textAlign: 'left',
    height: "60px",
  },
  avatar: {
    width: "120px",
    height: "120px",
    border: '2px solid white',
    boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.16)',
    transform: 'translateY(-60px)',
    '@media screen and (max-width: 768px) ': {
      margin: "auto"
    }
  },
  header: {
    width: '100%',
    height: '300px',
    background: `grey url(${imgHeader}) no-repeat center/100%`
  },
  itemTitle: {
    margin: "4px 0 0 0",
    textTransform: "uppercase"
  },
  name: {
    fontSize: '26px',
    fontWeight: 700,
    margin: '5px 0'
  },
  profileWrapper: {
    padding: "0 60px",
    '@media screen and (max-width: 768px) ': {
      padding: '0 10px'
    }
  }

}
))
