// Core
import React, { useEffect } from 'react'
// Material
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  createMuiTheme,
  createStyles,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  ThemeProvider,
  Typography
} from '@material-ui/core'
import { useAppStyles } from './message.style'
import passerelle from '../../../../../../../../services/passerelle'
import { Phone } from '@material-ui/icons'
import Skeleton from '@material-ui/lab/Skeleton'

export enum MessageType {
  IMAGE,
  TEXT,
  VIDEO,
  CARD,
}
interface IMessageType {
  type: MessageType,
  content: string
}

interface MessageProps {
  avatarUrl?: string
  author: string
  messages: IMessageType[]
}

export const Message = (props: MessageProps) => {
  const classes = useAppStyles()
  return (
    <ListItem className={classes.main}>
      <ListItemIcon>
        <Avatar
          src={props.avatarUrl}
          alt={``}
          className={classes.img}
        />
      </ListItemIcon>
      <ListItemText
        primary={`${props.author}`}
        secondary={props.messages.map((m: IMessageType) => <MessageLine {...m} />)}
        classes={{
          primary: classes.itemPrimary,
          secondary: classes.itemSecondary
        }}
      />
    </ListItem>
  )
}

const MessageLine = (props: IMessageType) => {
  const [content, setContent] = React.useState<any>(null);

  useEffect(() => {
    const asynfunc = async () => {
      if (props.type === MessageType.TEXT) {
        setContent(props.content);
      } else if (props.type === MessageType.CARD) {
        const cardType = props.content.split(':')[0];
        if (cardType === "school") {
          setContent(<CardSchoolSkeleton />);
          const structure = await passerelle.getStructureById(props.content.split(':')[1]);
          if (structure.data) {
            const data = structure.data.getStructureById;
            setContent(
              <CardSchool
                id={data.id}
                name={data.name}
                url={data.url}
                logo={data.logo}
                phone={data.phone}
                address={data.address}
              />
            )
          }
        }
      }
    }
    asynfunc();
  }, [props]);
  return (<div>{content}</div>)
}

interface CardSchoolProps {
  id: string
  name: string
  logo: string
  phone: string
  url: string
  address: {
    address1: string
    address2: string
    zipcode: string
    city: string
    email: string
  }
}

const defaultTheme = createMuiTheme()
const cardTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      subtitle1: {
        fontSize: 16
      },
      h5: {
        fontSize: 26,
        fontWeight: 'bold',
        marginBottom: 15,
      },
    },
    MuiButton: {
      root: {
        background: '#951556',
        borderRadius: 45,
        border: 0,
        color: 'white',
        height: 48,
        fontSize: '18px',
        fontWeight: 'bold',
        marginRight: 10,
        marginBottom: 10,
        '&:hover': {
          boxShadow: '2px 0px 5px 2px rgba(0, 0, 0, .3)',
          backgroundColor: '#35013f',
          color: 'white'
        },
        [defaultTheme.breakpoints.down('md')]: {
          width: '100%',
        },
      },
      text: {
        padding: '30px',
      },
      label: {
        textTransform: 'none',
      },
    },
    MuiIconButton: {
      root: {
        background: '#951556',
        borderRadius: 45,
        border: 0,
        color: 'white',
        height: 48,
        fontSize: '18px',
        fontWeight: 'bold',
        marginRight: 10,
        transform: 'scale(0.7)',
        '&:hover': {
          boxShadow: '2px 0px 5px 2px rgba(0, 0, 0, .3)',
          backgroundColor: '#35013f',
          color: 'white'
        }
      },
    },
  },
});


const CardSchoolSkeleton = () => {
  return (
    <Paper style={{ maxWidth: '75%', padding: 40 }}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Skeleton animation="wave" variant="circle" width={50} height={50} />
        </Grid>
        <Grid item xs={10} style={{ marginLeft: 10 }}>
          <Skeleton animation="wave" width={'100%'} height={40} />
          <Skeleton animation="wave" width={'100%'} height={40} />
          <Skeleton animation="wave" width={'100%'} height={40} />
          <Skeleton animation="wave" width={'100%'} height={40} />
        </Grid>
      </Grid>
    </Paper>
  )
}

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: 40,
      maxWidth: '75%',
      boxShadow: '3px 2px 24px 0 rgba(0, 0, 0, 0.09)',
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        flexDirection: 'column',
        padding: 20,
        textAlign: 'center',
      },
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 10px',
      maxWidth: '75%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    content: {
      flex: '1 0 auto',
    },
    avatarWrapper: {
      width: 150,
      maxWidth: '25%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
    avatar: {
      width: '100%',
      height: 'auto',
      border: '2px solid white',
      boxShadow: '3px 2px 10px 0 rgba(0, 0, 0, 0.30)',
      [theme.breakpoints.down('sm')]: {
        width: '80px',
        display: 'inline-block',
      },
    },
    action: {
      paddingTop: '20px',
    },
  }),
);

const CardSchool = (props: CardSchoolProps) => {
  const classes = useCardStyles(cardTheme);
  return (
    <ThemeProvider theme={cardTheme}>
      <Card className={classes.root}>
        <Box
          className={classes.avatarWrapper}
        >
          <Avatar
            src={props.logo !== null ? props.logo : '/images/structure.svg'}
            title={props.name}
            className={classes.avatar}
          />
        </Box>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {props.name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {props.address.address1 ? (<>{props.address.address1} <br /></>) : null}
              {props.address.address2 ? (<>{props.address.address2} <br /></>) : null}
              {props.address.zipcode} {props.address.city}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {props.phone}&nbsp;
              {props.phone ? (<IconButton href={`tel:${props.phone}`}><Phone /></IconButton>) : null}
            </Typography>
            <div className={classes.action}>
              {props.url ? (<Button href={props.url} target="_blank">Site Web</Button>) : null}
              {props.address.email ? (<Button href={`mailto:${props.address.email}`}>Contacter</Button>) : null}
            </div>
          </CardContent>
        </div>

      </Card>
    </ThemeProvider>
  )
}
