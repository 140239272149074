import { Reducer } from 'redux'
import { ChatAction } from './actions'

export interface ChatState {
  connected: boolean
  errors: any
  loading: boolean
}

const initialState: ChatState = {
  connected: false,
  errors: undefined,
  loading: false,
};

const reducer: Reducer<ChatState, ChatAction> =
  (state: ChatState = initialState, action: ChatAction) => {
    switch (action.type) {
      default:
        return state
    }
  };

export { reducer as chatReducer }
