// Core
import React from "react";

// Styles
import { Box, Avatar } from "@material-ui/core";
import { useAppStyles } from "../coremessaging.style";

import { Contact } from "../../../../../../../entity/Contact";

import withWidth from "@material-ui/core/withWidth";
import { displayName } from "../../../../../../../services/helper";

export interface MessagingHeaderProps {
  contact: Contact;
  width: string;
}

const MessagingHeader = (props: MessagingHeaderProps) => {
  const classes = useAppStyles();
  const { contact } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={classes.header}
    >
      <Box>
        <Avatar
          src={contact.avatar}
          alt={displayName(contact.firstName, contact.lastName)}
          className={classes.imgHeader}
        />
      </Box>
      <Box flex={1} className={classes.presentation}>
        <div className={classes.name}>
          {displayName(contact.firstName, contact.lastName)}
        </div>
        <div className={classes.baseline} title={contact.baseline}>
          {contact.baseline}
        </div>
      </Box>
      {/* <Box display="flex" alignItems="center" className={classes.tools}>
        {contact.isFavori ? (
          <Icon>favorite</Icon>
        ) : (
          <Icon>favorite_border</Icon>
        )}

        {
          useScreenToDisplay(width) &&
          <TextField
          id="outlined-dense"
          label=""
          margin="dense"
          variant="outlined"
          placeholder={"Recherche un message"}
          classes={{
            root: classes.searchRoot
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon style={{color: "#FFF"}}>search</Icon>
              </InputAdornment>
            )
          }}
        />}
        <Icon>videocam</Icon>
        <Icon>call</Icon>
        <Icon>more_vert</Icon>
    </Box>*/}
    </Box >
  );
};

export default withWidth()(MessagingHeader);
