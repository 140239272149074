import { action, ActionType } from "typesafe-actions";
import { Config } from "../../../services/chat";

export const INIT_CHAT = "INIT_CHAT";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
export const ADD_MESSAGE_RECEIVE = "ADD_MESSAGE_RECEIVE";
export const ADD_MESSAGE_SEND = "ADD_MESSAGE_SEND";

export const initChat = (config: Config) => action(INIT_CHAT, config);
export const sendMessage = (destinataire: string, message: string) =>
  action(SEND_MESSAGE, { destinataire, message });
export const receiveMessage = (body: any, idUser: string) =>
  action(RECEIVE_MESSAGE, { body, idUser });

export const addMessageSent = (
  content: string,
  senderId: string,
  timestamp: number,
  destinataire: string
) => {
  const message = {
    content,
    senderId,
    timestamp
  };
  return action(ADD_MESSAGE_SEND, { message, destinataire });
};
export const addMessageReceived = (
  content: any,
  senderId: string,
  timestamp: number
) => {
  return action(ADD_MESSAGE_RECEIVE, { content, senderId, timestamp });
};

const allActions = {
  initChat,
  sendMessage,
  receiveMessage,
  addMessageSent,
  addMessageReceived
}
export type ChatAction = ActionType<typeof allActions>
